import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";
import { useAuth } from "./useAuth";

const EventContext = createContext()

export const EventProvider = ({ id, children }) => 
{
    const {user} = useAuth()
    const [eventRefresh, setEventRefresh] = useState(0)
    const [event, setEvent] = useState(null)
    //const [competitor, setCompetitor] = useState(null)

    const getEventData = useMemo(() => async () => {
        if(id)
            setEvent((await doGet("/" + process.env.REACT_APP_CLUB + "/events/" + id)).data)
    }, [eventRefresh, id])


    useEffect(() => {
            getEventData()
        },[getEventData]
    );


    function reloadEvent()
    {
        setEventRefresh(eventRefresh + 1)
    }

    function getMatch(match_id)
    {
        if(event && event.days)
        {
            for(let i=0; i < (event.days).length; i++)
            {
                let day = event.days[i]
                for(let j=0; j < (day.matches).length; j++)
                {
                    let match = day.matches[j]
                    if(match.id === match_id)
                    {
                        return match
                    }
                }
            }
        }
        return null
    }

    function getDay(day_id)
    {
        if(event && event.days)
        {
            for(let i=0; i < (event.days).length; i++)
            {
                let day = event.days[i]
                if(day.id === day_id)
                {
                    return day
                }
            }
        }
        return null
    }

    return <EventContext.Provider value={{event, reloadEvent, getDay, getMatch}}>{children}</EventContext.Provider>;
}

export const useGetEvent = () => 
{
    return useContext(EventContext);
};