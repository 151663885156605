import HandicapTableLayout from "../../components/HandicapTableLayout"
import { useState } from "react"
import Table from "../../components/Table"
import { useGetData } from "../../api/hooks/useGetData"
import AddScoresForm from "../../components/forms/members/AddScoresForm"
import { groupScores } from "../../utils/groupScores"
import { postData } from "../../api/postData"
import { getCompetitor } from "../../utils/getCompetitor"
import { useAuth } from "../../utils/hooks/useAuth"
import { useModal } from "../../utils/hooks/useModal"
import { useGetMembers } from "../../utils/hooks/useGetMembers"

const url = "/" + process.env.REACT_APP_CLUB + "/members/"

function MembersHandicaps(){

    const {logout} = useAuth()
    const [loading, setLoading] = useState(false)

    const {members, reloadMembers} = useGetMembers()

    
    let modalData={
        "add": {
            title:"Add Scores", 
            data: <AddScoresForm members={members}/>, 
            callback: (formData) => addScores(formData)
        }, 
    }

    

    // const addScores = async (formData) => 
    // {
    //     let groupedScores = groupScores(formData)
    //     let request_arr = []

    //     for(let i=0; i < groupedScores.length; i++)
    //     {
    //         let member_score = groupedScores[i]
    //         let scores = member_score.score_string.split(",")

    //         for(let j=0; j < scores.length; j++)
    //         {
    //             let request_arr_item = {}
    //             let date = new Date(formData.date)
    //             request_arr_item.date = date.toISOString()
    //             request_arr_item.state_sid = member_score.state_sid
    //             request_arr_item.competitor_id = parseInt(member_score.competitor_id)
    //             request_arr_item.event_result_id = null
    //             request_arr_item.aggregate_score = parseInt(scores[j])

    //             let competitor = getCompetitor(parseInt(member_score.competitor_id), data)
    //             if(competitor)
    //             {
    //                 request_arr_item.max_score = competitor.handicap_type.max_score
    //             }

    //             request_arr.push(request_arr_item)
    //         }

    //     }

    //     let response = await postData(url + "handicapscores/", JSON.stringify(request_arr))
    //     if(response.error && response.error.response.status === 401)
    //     {
    //         logout();
    //     }
    //     setReload(true)
    //     return response
    // }

    const addScores = async (formData) => 
    {
        let groupedScores = groupScores(formData)
        let request_arr = []


        for(let i=0; i < groupedScores.length; i++)
        {
            let member_score = groupedScores[i]
            let scores = member_score.score_string.split(",")

            for(let j=0; j < scores.length; j++)
            {
                let request_arr_item = {}
                let date = new Date(formData.date)
                request_arr_item.date = date.toISOString()
                request_arr_item.state_sid = member_score.state_sid
                request_arr_item.competitor_id = parseInt(member_score.competitor_id)
                request_arr_item.event_id = null
                request_arr_item.round_result_id = null
                request_arr_item.aggregate_score = parseInt(scores[j])
                request_arr_item.order = 2

                let competitor = getCompetitor(parseInt(member_score.competitor_id), members)
                if(competitor)
                {
                    request_arr_item.max_score = competitor.handicap_type.max_score
                }

                request_arr.push(request_arr_item)

            }
            document.getElementById("modal-error").innerHTML = "Sent " + (i+1) + " Member's scores of " + groupedScores.length
            let response = await postData(url + "handicapscores", JSON.stringify(request_arr))
            if(response.error && response.error.response.status === 401)
            {
                logout();
            }
            request_arr = []
        }
        
        reloadMembers()
        return true
    }



    return (
        <>
        {loading && <div>Loading...</div>}
        {members && <div><HandicapTableLayout
            buttons={[
                {key: "add_scores", text: "+ Add Member Scores", modal: true, modalDataKey: "add", permission_max_level: 2},
                {key: "print", text: "Print", modal: false, modalDataKey: null, permission_max_level: 2, clickHandler: () => {window.print()}},
            ]}
            members={members}
            modalData={modalData}
                />
        </div>}
        </>
    )
}

export default MembersHandicaps