import BasicEditForm from '../BasicEditForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormDateInput from '../FormDateInput';
import FormCheckBox from '../FormCheckBox';
import FormSelect from '../FormSelect';
import FormHiddenInput from '../FormHiddenInput';

import { useState } from 'react';
import { useGetData } from '../../../api/hooks/useGetData';
import { useEffect } from 'react';
import { useGetClubDetails } from '../../../utils/hooks/useGetClubDetails';

const url = "/" + process.env.REACT_APP_CLUB + "/events"

function EditEventForm({off_rifle_prize_templates=[], handicap_prize_templates=[], seasons=[]}) {
    const [selected_grades, setSelectedGrades] = useState([])
    const {grade_templates} = useGetClubDetails()

    const onCheckMultiDay = (event) => {
        let end_date = document.getElementById("validationend_date")
        let start_date_label = document.getElementById("start_date_label")
        if(event.target.checked)
        {
            end_date.disabled = false
            start_date_label.innerHTML = "* Start Date"
        }
        else
        {
            end_date.disabled = true
            start_date_label.innerHTML = "* Date"
        }
    }


    function addSelectedGrades(event) {
        const selectedOptions = [...event.target.selectedOptions].map(o => o.value)
        setSelectedGrades(selectedOptions)
        document.getElementById("selected_grades").value = selectedOptions
      }


    function getGradeIdsFromObj(grade_array)
    {
        let ret_str = ""
        for(let i=0; i < grade_array.length; i++)
        {
            if(i == 0)
                ret_str += grade_array[i].id
            else
                ret_str += ("," + grade_array[i].id)
        }
        return ret_str
    }

    function putGradeIdsInArray(grade_array)
    {
        return getGradeIdsFromObj(grade_array).split(",")
    }

    function startDateLabel(label, data)
    {
        if(data["is_multi_day"])
            return "* Start Date"
        return "* Date"
    }

    function disabledModifier(disabled, data)
    {
        if(data["is_multi_day"])
            return false
        return true
    }


    return(
      <BasicEditForm url={url} titleFieldName={"name"} primaryKeyName={"id"} >
        <FormHiddenInput name="selected_grades" defaultValueField={"grades"} defaultValueModifier={getGradeIdsFromObj}/>
        <FormRow>
          <FormTextInput width="10" name="name" placeholder="Event Name" label="* Event Name" required={true} feedbackType="invalid" feedbackText="Please enter an Event Name" defaultValueField="name"/>     
        </FormRow>
        <FormRow>
            <FormCheckBox width="5" label="Multi Day Event" name="is_multi_day" defaultValueField={"is_multi_day"} onChange={(e) => {onCheckMultiDay(e)}}/>
            <FormCheckBox width="6"  label="Multiple Matches Per Day" name="is_multi_match" defaultValueField={"is_multi_match"} />
        </FormRow>
        <FormRow>
          <FormDateInput width="5" name="start_date" placeholder="Date" label="* Date" labelModifier={startDateLabel} labelId="start_date_label" required={true} feedbackType="invalid" feedbackText="Please enter a Start Date" defaultValueField="start_date"/>
          <FormDateInput width="5" name="end_date" placeholder="End Date" label="End Date" required={true} disabledModifier={disabledModifier} defaultValueField="end_date"/>
        </FormRow>
        <FormRow>
            <FormSelect width={4} name="season_id" label="* Season" placeholder="Season" required={true} feedbackType="invalid" feedbackText="Please Select a Season" defaultValueField={"season_id"}>
                <option value="0">None</option>
                {
                    seasons.map((season) => (
                        <option key={season.id} value={season.id}>{season.name}</option>
                    ))
                }
            </FormSelect>
        </FormRow>
        <FormRow>
            <FormSelect name="grades" label="* Grades" placeholder="Select Grades" multiple={true} required={true} feedbackType="invalid" feedbackText="Please Select Grades" defaultValueField={"grades"} defaultValueModifier={putGradeIdsInArray} onChange={(event) => addSelectedGrades(event)}>
                {grade_templates &&
                    grade_templates.map((grade_template) => (
                        <option key={grade_template.id} value={grade_template.id} >{grade_template.name}</option>
                    )) 
                }
            </FormSelect>
        </FormRow>
        <FormRow>
            <FormCheckBox width="5" label="Championship" name="is_championship" defaultValueField={"is_championship"} />
            <FormCheckBox width="5"  label="Handicapped" name="is_handicapped" defaultValueField={"is_handicapped"} />
        </FormRow>
        <FormRow>
            <FormSelect name="or_prize_template_id" label="* Off Rifle Prize Template" placeholder="Off Rifle Prize Template" required={true} feedbackType="invalid" feedbackText="Please Select Off Rifle Prize Template" defaultValueField={"or_prize_template_id"}>
                <option value="0">None</option>
                {
                    off_rifle_prize_templates.map((prize_template) => (
                        <option key={prize_template.id} value={prize_template.id}>{prize_template.name}</option>
                    ))
                }
            </FormSelect>
            <FormSelect name="h_prize_template_id" label="* Handicap Prize Template" placeholder="Handicap Prize Template" required={true} feedbackType="invalid" feedbackText="Please Select Handicap Prize Template" defaultValueField={"h_prize_template_id"}>
                <option value="0">None</option>
                {
                    handicap_prize_templates.map((prize_template) => (
                        <option key={prize_template.id} value={prize_template.id}>{prize_template.name}</option>
                    ))
                }
            </FormSelect>
        </FormRow>

      </BasicEditForm>
    )
}

export default EditEventForm