import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormDateInput from '../FormDateInput';
import FormCheckBox from '../FormCheckBox';
import FormSelect from '../FormSelect';

import { useState } from 'react';
import { useGetClubDetails } from '../../../utils/hooks/useGetClubDetails';

function AddEventForm({off_rifle_prize_templates=[], handicap_prize_templates=[], seasons=[], venues=[]}) {
    const [selected_grades, setSelectedGrades] = useState([])

    const [selectedVenue, setSelectedVenue] = useState(null)
    const {grade_templates} = useGetClubDetails()

    const onCheckMultiDay = (event) => {
        let end_date = document.getElementById("validationend_date")
        let start_date_label = document.getElementById("start_date_label")
        if(event.target.checked)
        {
            end_date.disabled = false
            start_date_label.innerHTML = "* Start Date"
        }
        else
        {
            end_date.disabled = true
            start_date_label.innerHTML = "* Date"
        }
    }

    const onUpdateVenue = (event) => {
        setSelectedVenue(event.target.value)
    }

    function getVenueWithId(id)
    {
        for(let i = 0; i < venues.length; i++)
        {
            let venue = venues[i]
            if(venue.id === parseInt(id))
            {         
                return venue
            }
        }
        return null

    }

    function addSelectedGrades(event) {
        const selectedOptions = [...event.target.selectedOptions].map(o => o.value)
        setSelectedGrades(selectedOptions)
        document.getElementById("selected_grades").value = selectedOptions
      }

    return(
    <>
      <BasicAddForm>
        <input type="hidden" id="selected_grades" name="selected_grades"/>
        <FormRow>
          <FormTextInput width="10" name="name" placeholder="Event Name" label="* Event Name" required={true} feedbackType="invalid" feedbackText="Please enter an Event Name"/>     
        </FormRow>
        <FormRow>
            <FormCheckBox width="5" label="Multi Day Event" name="is_multi_day" defaultChecked={false} onChange={(e) => {onCheckMultiDay(e)}}/>
            <FormCheckBox width="6"  label="Multiple Matches Per Day" name="is_multi_match" defaultChecked={false} />
        </FormRow>
        <FormRow>
          <FormDateInput width="5" name="start_date" placeholder="Date" label="* Date" labelId="start_date_label" required={true} feedbackType="invalid" feedbackText="Please enter a Start Date"/>
          <FormDateInput width="5" name="end_date" placeholder="End Date" label="End Date" required={true} disabled={true} />
        </FormRow>
        <FormRow>
            <FormSelect width={4} name="season_id" label="* Season" placeholder="Season" required={true} feedbackType="invalid" feedbackText="Please Select a Season" defaultValue="0">
                <option value="0">None</option>
                {
                    seasons.map((season) => (
                        <option key={season.id} value={season.id}>{season.name}</option>
                    ))
                }
            </FormSelect>
            <FormSelect width={4} name="venue_id" label="* Venue" placeholder="Venue" required={true} feedbackType="invalid" feedbackText="Please Select Venue" defaultValue="0" onChange={(e) => {onUpdateVenue(e)}}>
                <option value="0">None</option>
                {
                    venues.map((venue) => (
                        <option key={venue.id} value={venue.id}>{venue.name}</option>
                    ))
                }
            </FormSelect>
            <FormSelect width={3} name="distance_id" label="* Distance" placeholder="Distance" required={true} feedbackType="invalid" feedbackText="Please Select Distance" defaultValue="0">
                {
                    selectedVenue && getVenueWithId(selectedVenue).distances.map((distance) => (
                        <option key={distance.id} value={distance.id}>{distance.name}</option>
                    ))
                }
            </FormSelect>
        </FormRow>
        <FormRow>
            <FormSelect name="grades" label="* Grades" placeholder="Select Grades" multiple={true} required={true} feedbackType="invalid" feedbackText="Please Select Grades" defaultValue={[]} onChange={(event) => addSelectedGrades(event)}>
                {grade_templates &&
                    grade_templates.map((grade_template) => (
                        <option key={grade_template.id} value={grade_template.id}>{grade_template.name}</option>
                    )) 
                }
            </FormSelect>
        </FormRow>
        <FormRow>
            <FormCheckBox width="5" label="Championship" name="is_championship" defaultChecked={true} />
            <FormCheckBox width="5"  label="Handicapped" name="is_handicapped" defaultChecked={true} />
        </FormRow>
        <FormRow>
            <FormSelect name="or_prize_template_id" label="* Off Rifle Prize Template" placeholder="Off Rifle Prize Template" required={true} feedbackType="invalid" feedbackText="Please Select Off Rifle Prize Template" defaultValue="0">
                <option value="0">None</option>
                {
                    off_rifle_prize_templates.map((prize_template) => (
                        <option key={prize_template.id} value={prize_template.id}>{prize_template.name}</option>
                    ))
                }
            </FormSelect>
            <FormSelect name="h_prize_template_id" label="* Handicap Prize Template" placeholder="Handicap Prize Template" required={true} feedbackType="invalid" feedbackText="Please Select Handicap Prize Template" defaultValue="0">
                <option value="0">None</option>
                {
                    handicap_prize_templates.map((prize_template) => (
                        <option key={prize_template.id} value={prize_template.id}>{prize_template.name}</option>
                    ))
                }
            </FormSelect>
        </FormRow>
      </BasicAddForm>
    </>
    )
}

export default AddEventForm