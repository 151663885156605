import { ModalProvider } from "./useModal";
import { MembersProvider } from "./useGetMembers";
import { ClubDetailsProvider } from "./useGetClubDetails";
import { GlobalProvider } from "./useGetGlobal";

function GlobalProviders({children}){
    return(
        <GlobalProvider>
            <ClubDetailsProvider>
                <MembersProvider>
                    <ModalProvider>
                        {children}
                    </ModalProvider>
                </MembersProvider>
            </ClubDetailsProvider>
        </GlobalProvider>
    )
}

export default GlobalProviders