import { useParams } from "react-router-dom"
import "./css/member-detail.css"
import { useState, useEffect } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import DetailLayout from "../../components/DetailLayout"
import OnClickButton from "../../components/OnClickButton"
import EditMemberForm from "../../components/forms/members/EditMemberForm"
import AddDisciplineForm from "../../components/forms/members/AddDisciplineForm"
import EditDisciplineForm from "../../components/forms/members/EditDisciplineForm"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import MemberDetailContent from "../../components/details/members/MemberDetailContent"

import { useAuth } from "../../utils/hooks/useAuth"
import { useGetMember } from "../../utils/hooks/useGetMember"

const normal_url = "/" + process.env.REACT_APP_CLUB + "/members/"
const superadmin_url = "/na/members/"
const user_url = "/users/"

function MemberDetail()
{
    let params = useParams()

    const {user, logout} = useAuth()
    const {member, reloadMember} = useGetMember()

    const [loading, setLoading] = useState(false)

    let url = normal_url
    if(params.username === user.username && user.user_role === "superadmin")
    {
        url = superadmin_url
    }

    const getLogins = () => {
        
    }

    const updateMember = async (formData) => 
    {
        let user_request_json = 
        {
            username: formData.username, 
            email: formData.email, 
            full_name: formData.full_name, 
            user_role: formData.user_role, 
            disabled: false,
        }
        let member_request_json = 
        {
            username: formData.username, 
            state_sid: formData.username,
            nraa_sid: formData.nraa_sid,
            address1: formData.address,
            address2: formData.address2,
            suburb: formData.suburb,
            state: formData.state,
            postcode: formData.postcode,
            phone: formData.phone,
            active: true,
            private: false,
        }

        let user_response = null
        let member_response = null

        if(user.username == formData.username)
        {
            user_response = await patchData(user_url, "me", JSON.stringify(user_request_json))
            member_response = await patchData(url, "me", JSON.stringify(member_request_json))
            if((user_response.error && member_response.error) && (user_response.error.response.status === 401 || member_response.error.response.status === 401))
            {
                logout()
            }
        }
        else
        {
            user_response = await patchData(user_url, formData.username, JSON.stringify(user_request_json))
            member_response = await patchData(url, formData.username, JSON.stringify(member_request_json))
            if((user_response.error && member_response.error) && (user_response.error.response.status === 401 || member_response.error.response.status === 401))
            {
                logout()
            }
        }


        if(member_response.error == null)
        {
            // member_request_json.state_sid = user_request_json.username
            // member_request_json.user = {username: user_request_json.username, email: user_request_json.email, full_name: user_request_json.full_name, user_role: user_request_json.user_role, disabled: user_request_json.disabled}
            // member_request_json.competitors = member.competitors
            // member_request_json.user.role = member.user.role

            // if(data.username == member_request_json.username)
            // {
            //     if(member_request_json.user.user_role != data.user.user_role)
            //     {
            //         setReload(true)
            //     }
            //     setData(member_request_json)  
            // }
            reloadMember()
        }

        return member_request_json
    }

    const addDiscipline = async (formData) => 
    {
        let response = await postData(url + params.username + "/competitors/", JSON.stringify(formData))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadMember()
            return response
        }
    }

    const editDiscipline = async (formData) => 
    {
        let request_form_data = 
        {
            e_target_id: formData.e_target_id
        }
        let response = await patchData(url + params.username + "/competitors/", formData.id, JSON.stringify(request_form_data))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadMember()
            return response
        }
    }

    const deleteDiscipline = async(e, id) => {
        e.preventDefault();
        if(window.confirm("Are you sure you want to delete Discipline from Member"))
        {
            let response = await deleteData(url + member.state_sid + "/competitors/", id)
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                reloadMember()
                return response
            }
        }
    }

    let modalData = {
        "edit": {
            title: "Edit Member",
            data: <EditMemberForm />,
            callback: (formData) => updateMember(formData),
        },
        "add_discipline": {
            title: "Add Discipline",
            data: <AddDisciplineForm state_sid={params.username}/>,
            callback: (formData) => addDiscipline(formData),
        },
        "edit_discipline": {
            title: "Edit Discipline",
            data: <EditDisciplineForm state_sid={params.username}/>,
            callback: (formData) => editDiscipline(formData),
        },
    }

    return (
        <>
        {loading && <div>Loading...</div>}
        {member && 
        <div className="container">
            <DetailLayout 
                contentMenu={{
                    backUrl: "/home/members/", 
                    backText: "Back to Member List",
                    buttons: 
                    [
                        //{id: "Logins", content: <OnClickButton id={data.username} text="Logins" onClickHandler={getLogins} permission_max_level={2} permission_id_override={user.username} />}
                    ]
                }} 
                modalData={modalData}
                content={<MemberDetailContent data={member} modalData={modalData} disciplineDeleteHandler={deleteDiscipline}/>}
            >
            </DetailLayout>
            </div>
            }
        </>
    )
}

export default MemberDetail