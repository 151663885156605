import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";

const HandicapTypeContext = createContext()

export const HandicapTypeProvider = ({ id, children }) => 
{
    const [handicapTypeRefresh, setHandicapTypeRefresh] = useState(0)
    const [handicapType, setHandicapType] = useState(null)

    const getHandicapTypeData = useMemo(() => async () => {
        setHandicapType((await doGet("/" + process.env.REACT_APP_CLUB + "/members/handicaptypes/" + id)).data)
    }, [handicapTypeRefresh])


    useEffect(() => {
            getHandicapTypeData()
        },[getHandicapTypeData]
    );


    function reloadHandicapType()
    {
        setHandicapTypeRefresh(handicapTypeRefresh + 1)
    }

    return <HandicapTypeContext.Provider value={{handicapType, reloadHandicapType}}>{children}</HandicapTypeContext.Provider>;
}

export const useGetHandicapType = () => 
{
    return useContext(HandicapTypeContext);
};