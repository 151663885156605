import { formatDateTextDayName } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"
import OnClickButton from "../../OnClickButton"
import { useGetEvent } from "../../../utils/hooks/useGetEvent"
import { useModal } from "../../../utils/hooks/useModal"
import { useAuth } from "../../../utils/hooks/useAuth"
import EditRoundForm from "../../forms/events/EditRoundForm"
import { patchData } from "../../../api/patchData"
import { deleteData } from "../../../api/deleteData"

function EventRoundLayout({round=null, children})
{
    const {event, reloadEvent, getMatch} = useGetEvent()
    let match = getMatch(round.match_id)
    const url = "/" + process.env.REACT_APP_CLUB + "/events/" + event.id + "/days/" + match.day_id + "/matches/" + round.match_id + "/rounds"
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()
    const {logout} = useAuth()

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)
    
        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    let modalData={
        "edit": {
            title:"Edit Round", 
            data: <EditRoundForm match_id={round.match_id}/>, 
            callback: (formData) => editRound(formData)
        },
    }

    const editRound = async (formData) => {
        let response = await patchData(url + "/", formData.id, formData)
        
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            
            reloadEvent()
            return response
        }
    }

    const deleteRound = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Round"))
        {
            let response = await deleteData(url + "/", id)
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                reloadEvent()
                return response
            }
        }
    }

    return(
        <>
        <div className="event-round-container">
            <div className="event-round-title-bar event-title-bar row">
                <div className="col">
                    <div className="event-round-title"><strong>Round:</strong> {round.round_num}</div>
                    <div className="event-round-title"><strong>Counters:</strong> {round.counters}</div>
                    <div className="event-round-title"><strong>Sighters:</strong> {round.sighters}</div>
                </div>
                <div className="event-round-button event-title-bar-button col-lg-auto">
                    <OnClickButton text="Edit Round" onClickHandler={(e) => handleClickEdit(e,true, "edit", round.id, null)} permission_max_level={2}/>
                    <OnClickButton text="Delete Round" onClickHandler={(e) => deleteRound(e, round.id)} permission_max_level={2}/>
                </div>
            </div>
        </div>
        {children}
        </>
    )

}

export default EventRoundLayout