import ShotCell from "./ShotCell"
import ScoreCheckBox from "./ScoreCheckBox"
import { getGrade, getCounterShotArrayFromResult, getSighterShotArrayFromResult, setCharAt, setScoreAtIndex, getAllowedInput, getScoreMapArray, getClass, addScore, calculateAggregate, isSighterConverted } from "../utils/eventCompetitorUtils"
import { replaceCountersWithSighters } from "../utils/eventCompetitorUtils"
import BasicTable from "./tables/BasicTable"
import BasicHead from "./tables/BasicHead"
import BasicBody from "./tables/BasicBody"
import { useContext } from "react"
import { useState } from "react"
import OnClickButton from "./OnClickButton"
import { patchData } from "../api/patchData"
import { useAuth } from "../utils/hooks/useAuth"
import { precisionRound } from "../utils/precisionRound"
import { useGetGlobal } from "../utils/hooks/useGetGlobal"
import { useGetEvent } from "../utils/hooks/useGetEvent"

function ScoreEntry({event_competitor, match_result, round_results, match})
{
    const {logout} = useAuth()
    const [round_results_state, setRoundResultsState] = useState(round_results)
    const {classes} = useGetGlobal()
    const {event} = useGetEvent()

    const [lastSaved, setLastSaved] = useState("Not Saved")

    const [isHandicapped, setIsHandicapped] = useState(match_result.is_handicapped) 
    const [isChampionship, setIsChampionship] = useState(match_result.is_championship) 

    let grade = getGrade(event_competitor.grade_id, event.grades)
 
    const match_url = "/" + process.env.REACT_APP_CLUB + "/events/" + event.id + "/days/" + match.day_id + "/matches"
    const round_url = match_url + "/" + match.id + "/rounds"


    let allowedInput = []
    if(classes && event_competitor.discipline.handicap_type.class_list[0])
    {
        let _class = getClass(event_competitor.discipline.handicap_type.class_list[0].class_id, classes)
        allowedInput = getAllowedInput(_class)
    }

    let scoreMap = []
    if(classes && event_competitor.discipline.handicap_type.class_list[0])
    {
        let _class = getClass(event_competitor.discipline.handicap_type.class_list[0].class_id, classes)
        scoreMap = getScoreMapArray(_class)
    }

    function counterChange(event, index, round)
    {
        if(allowedInput.includes(event.target.value))
        {
            let newCountersStr = setScoreAtIndex(round_results_state[round-1].result.counters, index, event.target.value)
            if(newCountersStr)
                round_results_state[round-1].result.counters = newCountersStr
            setRoundResultsState([...round_results_state])

            var shot_inputs = document.getElementsByClassName(round_results_state[round-1].result.id + "_counter")
            Array.from(shot_inputs).forEach(function(shot_input){
                    if (shot_input.id === round_results_state[round-1].result.id + "_counter" + "_" + index) {
                    // Focus on the next sibling

                    if(shot_input.nextElementSibling)
                        shot_input.nextElementSibling.focus()
                }
            })
        }
        setLastSaved("Not Saved")
    }

    function sighterChange(event, index, round)
    {
        if(allowedInput.includes(event.target.value))
        {
            let newSightersStr = setScoreAtIndex(round_results_state[round-1].result.sighters, index, event.target.value)
            if(newSightersStr)
                round_results_state[round-1].result.sighters = newSightersStr
            setRoundResultsState([...round_results_state])
            var shot_inputs = document.getElementsByClassName(round_results_state[round-1].result.id + "_sighter")
            Array.from(shot_inputs).forEach(function(shot_input){

                    if (shot_input.id === round_results_state[round-1].result.id + "_sighter" + "_" + index) {
                    // Focus on the next sibling

                    if(shot_input.nextElementSibling)
                        shot_input.nextElementSibling.focus()
                }
            })
        }
        setLastSaved("Not Saved")
    }

    function sighterConvert(event, index, round)
    {
        if(event.target.value === "")
            event.target.value = "0"
            let newSightersStr = setScoreAtIndex(round_results_state[round-1].result.sighters, index, event.target.value)
            if(newSightersStr)
                round_results_state[round-1].result.sighters = newSightersStr

        if(index === 0)
        {
            round_results_state[round-1].result.sighters_converted = 2
            round_results_state[round-1].result.counters = replaceCountersWithSighters(round_results_state[round-1].result.counters, round_results_state[round-1].result.sighters, 2)
            document.getElementById(round_results_state[round-1].result.id + "_counter_2").focus()
        }
        else if(index === 1)
        {
            round_results_state[round-1].result.sighters_converted = 1
            round_results_state[round-1].result.counters = replaceCountersWithSighters(round_results_state[round-1].result.counters, round_results_state[round-1].result.sighters, 1)
            document.getElementById(round_results_state[round-1].result.id + "_counter_1").focus()
        }

        setRoundResultsState([...round_results_state])
    }
    
    function sighterUnConvert(event, index, round)
    {
        if(index === 0)
        {
            round_results_state[round-1].result.sighters_converted = 1
        }
        else if(index === 1)
        {
            round_results_state[round-1].result.sighters_converted = 0
        }
        setRoundResultsState([...round_results_state])
    }

    const saveScores = async () =>
    {
        let totalAggregate = 0.0
        for(let i=0; i < round_results_state.length; i++)
        {
            let round_result = round_results_state[i]
            let shotArray = getCounterShotArrayFromResult(round_result)

            let round_aggregate = addScore(shotArray, scoreMap)
            totalAggregate += round_aggregate

            let request_json = {
                match_result_id: match_result.id,
                event_competitor_id: event_competitor.id,
                sighters: round_result.result.sighters,
                counters: round_result.result.counters,
                sighters_converted: round_result.result.sighters_converted,
                aggregate: round_aggregate
            }

            let response = await patchData(round_url + "/" + round_result.result.round_id + "/roundresults/", round_result.result.id, request_json)
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                setLastSaved("Saved")
            }
        }

        let request_json =
        {
            day_result_id: match_result.day_result_id,
            event_competitor_id: event_competitor.id,
            is_handicapped: isHandicapped,
            is_championship: isChampionship,
            handicap: match_result.handicap,
            aggregate: precisionRound(totalAggregate, 2),
            total: 0
        }
        let response = await patchData(match_url + "/" + match_result.match_id + "/matchresults/", match_result.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }

    }

    function onHandicappedChecked(event)
    {
        if(event.target.checked)
        {
            setIsHandicapped(true)
        }
        else setIsHandicapped(false)
    }

    function onChampionshipChecked(event)
    {
        if(event.target.checked)
        {
            setIsChampionship(true)
        }
        else setIsChampionship(false)
    }

    return(
        <>

        <div className="event-competitor-title-bar">
            <div className="event-competitor-title"><h5>{event_competitor.name}</h5></div>
            <div className="event-competitor-title"><strong>Discipline:</strong> {event_competitor.discipline.handicap_type.name}</div>
            <div className="event-competitor-title"><strong>Grade:</strong> {grade.name}</div>
            <div className="event-competitor-title"><strong>Handicap:</strong> {match_result.handicap}</div>
        </div>
        <div className="score-table">
        <BasicTable edit={false} del={false} >
            <BasicHead>
                <th>H</th>
                <th>C</th>
                <th>Sighters</th>
                <th>Counters</th>
                <th>Score</th>
                <th>Agg</th>
                <th></th>
            </BasicHead>
            <BasicBody>
                <tr>
                    <td>
                        <ScoreCheckBox id="is_handicap" name="is_handicap" value={"on"} checked={isHandicapped} onChange={onHandicappedChecked}/>
                    </td>
                    <td>
                        <ScoreCheckBox id="is_championship" name="is_championship" value={"on"} checked={isChampionship} onChange={onChampionshipChecked}/>
                    </td>
                    <td>
                    {
                        round_results_state && round_results_state.map((round_result) => {
                            let shotArray = getSighterShotArrayFromResult(round_result)

                            return(
                                    <div key={round_result.round_num} className="shot-score-row">
                                    <strong>R{round_result.round_num} </strong> 
                                    {
                                    shotArray.map((shot_str, index) => {
                                        let num_converted = round_result.result.sighters_converted
                                        let is_selected = isSighterConverted(index, num_converted)

                                        return(<ShotCell className={round_result.result.id + "_sighter"} key={index} round_num={round_result.round_num} index={index} value={shot_str} isSighter={true} isSelected={is_selected} allowedInput={allowedInput} onChange={sighterChange} onSighterConvert={sighterConvert} onSighterUnConvert={sighterUnConvert}/>)
                                    })
                                    }
                                    </div>
                            )
                        })
                    }
                    </td>
                    <td>
                    {
                        round_results_state && round_results_state.map((round_result) => {
                            let shotArray = getCounterShotArrayFromResult(round_result)

                            return(
                                    <div key={round_result.round_num} className="shot-score-row">
                                    
                                    {
                                    shotArray.map((shot_str, index) => {
                                        return(<ShotCell className={round_result.result.id + "_counter"} key={index} round_num={round_result.round_num} index={index} value={shot_str} allowedInput={allowedInput} onChange={counterChange}/>)
                                    })
                                    }
                                    </div>
                            )
                        })
                    }
                    </td>
                    <td>
                        {
                            round_results_state && round_results_state.map((round_result) => {
                                let shotArray = getCounterShotArrayFromResult(round_result)
                                return(
                                        <div key={round_result.round_num} className="round-total">
                                        {
                                            addScore(shotArray, scoreMap)
                                        }
                                        </div>
                                )
                            })
                        }
                    </td>
                    <td>
                        <div className="match-aggregate-total">
                        {
                            calculateAggregate(round_results_state, scoreMap)
                        }
                        </div>
                    </td>
                    <td>
                        <div>{lastSaved}</div>
                        <OnClickButton text="Save Scores" onClickHandler={() => saveScores()} permission_max_level={2}/>
                    </td>
                </tr>
            </BasicBody>
        </BasicTable>
        </div>
        </>
    )
}

export default ScoreEntry