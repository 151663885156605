import { getEventCompetitor } from "../../../utils/eventCompetitorUtils"
import { useContext } from "react"
import { Fragment } from "react"

import { useGetEvent } from "../../../utils/hooks/useGetEvent"

function EventResultsTab({match, children})
{
    const {event} = useGetEvent()
    return( 
        match.match_results.map((match_result) => {
            let event_competitor = getEventCompetitor(match_result.event_competitor_id, event.competitors)
            return(
            <Fragment key={match_result.id}>
            <h5>Results</h5>
            <div >
                {event_competitor.name}
            </div>
            </Fragment>
            )
        })
    )
}

export default EventResultsTab