import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";

const EventsContext = createContext()

export const EventsProvider = ({ children }) => 
{
    const [eventsRefresh, setEventsRefresh] = useState(0)
    const [eventsOrderBy, setEventsOrderBy] = useState("start_date")
    const [eventsDescending, setEventsDescending] = useState(false)
    const [events, setEvents] = useState([])
    
    const [orRefresh, setORRefresh] = useState(0)
    const [off_rifle_templates, setORTemplates] = useState([])
    
    const [hcpRefresh, setHcpRefresh] = useState(0)
    const [handicap_templates, setHcpTemplates] = useState([])

    const getEventData = useMemo(() => async () => {
        setEvents((await doGet("/" + process.env.REACT_APP_CLUB + "/events/?order_by=" + eventsOrderBy + "&descending=" + eventsDescending)).data)
    }, [eventsRefresh, eventsOrderBy, eventsDescending])

    const getORData = useMemo(() => async () => {
        setORTemplates((await doGet("/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/offrifle")).data)
    }, [orRefresh])

    const getHcpData = useMemo(() => async () => {
        setHcpTemplates((await doGet("/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/handicap")).data)
    }, [hcpRefresh])

    useEffect(() => {
            getEventData()
        },[getEventData]
    );

    useEffect(() => {
        getORData()
    },[getORData]
    );

    useEffect(() => {
        getHcpData()
    },[getHcpData]
    );

    function reloadEvents()
    {
        setEventsRefresh(eventsRefresh + 1)
    }

    function toggleEventsDescending()
    {
        if(eventsDescending)
            setEventsDescending(false)
        else
            setEventsDescending(true)
    }

    function orderEventsBy(order_by)
    {
        setEventsOrderBy(order_by)
    }

    function reloadORTemplates()
    {
        setORRefresh(orRefresh + 1)
    }

    function reloadHcpTemplates()
    {
        setHcpRefresh(hcpRefresh + 1)
    }


    return <EventsContext.Provider value={{events, off_rifle_templates, handicap_templates, reloadEvents, toggleEventsDescending, orderEventsBy, reloadHcpTemplates, reloadORTemplates}}>{children}</EventsContext.Provider>;
}

export const useGetEvents = () => 
{
    return useContext(EventsContext);
};