import { formatDateTextDayName } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"
import EventRoundLayout from "./EventRoundLayout"
import { useState } from "react"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import OnClickButton from "../../OnClickButton"
import { useModal } from "../../../utils/hooks/useModal"
import { useGetEvent } from "../../../utils/hooks/useGetEvent"

import { useAuth } from "../../../utils/hooks/useAuth"
import AddRoundForm from "../../forms/events/AddRoundForm"
import EditMatchForm from "../../forms/events/EditMatchForm"

import { postData } from "../../../api/postData"
import { patchData } from "../../../api/patchData"
import { deleteData } from "../../../api/deleteData"

import EventCompetitorTab from "./EventCompetitorTab"
import EventScoresTab from "./EventScoresTab"
import EventResultsTab from "./EventResultsTab"



function EventMatchLayout({match=null, children})
{
    const [key, setKey] = useState("rounds")
    const {event, reloadEvent} = useGetEvent()
    const url = "/" + process.env.REACT_APP_CLUB + "/events/" + event.id + "/days/" + match.day_id + "/matches"
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()
    const {logout} = useAuth()
    

    const handleClickAdd = (event, modal, modalDataKey, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    let modalData={
        "add": {
            title:"Add Round", 
            data: <AddRoundForm />, 
            callback: (formData) => addRound(formData)
        },
        "edit": {
            title:"Edit Match", 
            data: <EditMatchForm match_id={match.id}/>, 
            callback: (formData) => editMatch(formData)
        },
    }

    const editMatch = async (formData) => {

        let request_json = {
            name: formData.name,
            distance_id: parseInt(formData.distance_id),
            or_prize_template_id: parseInt(match.or_prize_template_id),
            h_prize_template_id: parseInt(match.h_prize_template_id),
            prizes: "[]"
        }


        let response = await patchData(url + "/", formData.id, request_json)
        
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            
            reloadEvent()
            return response
        }
    }

    const addRound = async (formData) => {
        let response = await postData(url + "/" + match.id + "/rounds", formData)

        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvent()
            return response
        }
    }

    const deleteMatch = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Match"))
        {
            let response = await deleteData(url + "/", id)
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                //setData(data.filter(element => element.id !== id))
                reloadEvent()
                return response
            }
        }
    }

    return(
        <>
        <div className="event-match-container">
            <div className="event-match-title-bar event-title-bar row">
                <div className="col">
                    <div className="event-match-title"><strong>Match:</strong> {match.name}</div>
                    <div className="event-match-title"><strong>Distance:</strong> {match.distance.name}</div>
                    <div className="event-match-title"><strong>Rounds:</strong> {match.rounds.length}</div>
                </div>
                <div className="event-match-button event-title-bar-button col-lg-auto">
                    <OnClickButton text="Edit Match" onClickHandler={(e) => handleClickEdit(e,true, "edit", match.id, null)} permission_max_level={2}/>
                    <OnClickButton text="Delete Match" onClickHandler={(e) => deleteMatch(e, match.id)} permission_max_level={2}/>
                    <OnClickButton text="Add Round" onClickHandler={(e) => handleClickAdd(e,true, "add", null)} permission_max_level={2}/>
                </div>
            </div>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="rounds" title="Rounds">
                    {
                        match.rounds && 
                        match.rounds.map((round) => 
                            <EventRoundLayout key={round.id} round={round} />
                        )
                    }
                </Tab>
                <Tab eventKey="competitors" title="Competitors">
                    <EventCompetitorTab match_id={match.id} day_id={match.day_id} event_id={event.id}/>
                </Tab>
                {event.open ?
                <Tab eventKey="scores" title="Scores">
                    <EventScoresTab match={match}/>
                </Tab>
                :
                <Tab eventKey="results" title="Results">
                    <EventResultsTab match={match}/>
                </Tab>
                }
                <Tab eventKey="prizes" title="Prizes">
                    Prizes
                </Tab>
            </Tabs>
        </div>
        {children}
        </>
    )

}

export default EventMatchLayout