import { useGetEvent } from '../../../utils/hooks/useGetEvent';
import BasicEditForm from '../BasicEditForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormDateInput from '../FormDateInput';
import FormSelect from '../FormSelect';
import { useGetClubDetails } from '../../../utils/hooks/useGetClubDetails';

const url = "/" + process.env.REACT_APP_CLUB + "/events/"

function EditDayForm() {
    const {event} = useGetEvent()
    const {venues} = useGetClubDetails()
    return(
      <BasicEditForm url={url + event.id + "/days"} titleFieldName={"name"} primaryKeyName={"id"}>
        <FormRow>
            <FormTextInput width="4" name="name" placeholder="Day Name" label="* Day Name" required={true} feedbackType="invalid" feedbackText="Please enter a Day Name" defaultValueField="name"/>  
            <FormSelect width={3} name="venue_id" label="* Venue" placeholder="Venue" required={true} feedbackType="invalid" feedbackText="Please Select Venue" defaultValueField={"venue_id"}>
                {
                    venues && venues.map((venue) => (
                        <option key={venue.id} value={venue.id}>{venue.name}</option>
                    ))
                }
            </FormSelect>
            <FormDateInput width="4" name="date" placeholder="Date" label="* Date" labelId="date_label" required={true} feedbackType="invalid" feedbackText="Please enter a Date" defaultValueField="date"/>  
        </FormRow>
      </BasicEditForm>
    )
}

export default EditDayForm