import { postData } from "../../api/postData"
import { patchData } from "../../api/patchData"
import Table from "../../components/Table"
import ListTableLayout from "../../components/ListTableLayout"
import AddHandicapTypeForm from "../../components/forms/members/AddHandicapTypeForm"
import EditHandicapTypeForm from "../../components/forms/members/EditHandicapTypeForm"
import { useState, useEffect } from "react"
import { getBodyData } from "../../utils/getBodyData"
import { groupClasses } from "../../utils/groupClasses"
import { useModal } from "../../utils/hooks/useModal"
import { useAuth } from "../../utils/hooks/useAuth"
import { useGetGlobal } from "../../utils/hooks/useGetGlobal"
import { useGetMembers } from "../../utils/hooks/useGetMembers"
import "./css/handicap-types.css"

const url = "/" + process.env.REACT_APP_CLUB + "/members/handicaptypes/"

const router_url = "/home/members/handicaptypes/"

function MemberHandicapTypes()
{
    const {modalOpen} = useModal()
    const {logout} = useAuth()
    const {classes, reloadClasses} = useGetGlobal()
    const {disciplines, reloadDisciplines} = useGetMembers()

    const [loading, setLoading] = useState(false) 


        
    const headData = ["Name"]
    const fields = ["name"]
    const urlFields = [true]
    const idField = "id"

    let bodyData = getBodyData(disciplines, fields, idField, urlFields)


    const addHandicapType = async (formData) => 
    {
        let classes = groupClasses(formData)

        let use_rounds = false
        let normalize = false

        if(formData.use_rounds && formData.use_rounds == "on")
        {
            use_rounds = true
        }
        if(formData.normalize && formData.normalize == "on")
        {
            normalize = true
        }

        let request_json = 
        {
            name: formData.name,
            classes: classes,
            total_scores: formData.total_scores,
            best_scores: formData.best_scores,
            use_rounds: use_rounds,
            max_score: formData.max_score,
            normalize: normalize,
            normalize_score: formData.normalize_score,
            benchmark_score: formData.benchmark_score,
            benchmark_multiplier: formData.benchmark_multiplier
        }

        let response = await postData(url, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadDisciplines()
        }
        return response
    }

    const updateHandicapType = async (formData) => 
    {
        let classes = groupClasses(formData)

        let use_rounds = false
        let normalize = false

        if(formData.use_rounds && formData.use_rounds == "on")
        {
            use_rounds = true
        }
        if(formData.normalize && formData.normalize == "on")
        {
            normalize = true
        }

        let request_json = 
        {
            name: formData.name,
            classes: classes,
            total_scores: formData.total_scores,
            best_scores: formData.best_scores,
            use_rounds: use_rounds,
            max_score: formData.max_score,
            normalize: normalize,
            normalize_score: formData.normalize_score,
            benchmark_score: formData.benchmark_score,
            benchmark_multiplier: formData.benchmark_multiplier
        }

        let response = await patchData(url, formData.id, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadDisciplines()
        }
        return response
    }

    const deleteHandicapType = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Handicap Discipline"))
        {
            // let response = await deleteData(url, id)

            // if(response.error && response.error.response.status === 401)
            // {
            //     logout()
            // }
            // else
            // {
            //     setData(data.filter(element => element.id !== id))
            //     return response
            // }
        }
    }

    let modalData = {
        "add": {
            title:"Add New Handicap Type", 
            data: <AddHandicapTypeForm classData={classes}/>, 
            callback: (formData) => addHandicapType(formData)
        }, 
        "edit": {
            title: "Edit Handicap Type", 
            data: <EditHandicapTypeForm classData={classes}/>, 
            callback: (formData) => updateHandicapType(formData)}
    }

    const editHandicapTypes = (e, id) => {
        e.preventDefault();
        modalOpen(modalData["edit"], id)
    }
    
    return (
        <>
        {loading && <div>Loading...</div>}
        {bodyData && <div><h3>Handicap Disciplines</h3><ListTableLayout 
            buttons={[
                {key: "add_button", text: "+ Add Handicap Type", modal: true, modalDataKey: "add", permission_max_level: 2},
            ]}
            table={
                <Table customClass={"sm-table handicap-types-table"} theadData={headData} tbodyData={bodyData} url={router_url} edit={true} editHandler={editHandicapTypes} del={true} deleteHandler={deleteHandicapType}/>
            }
            modalData={modalData}
                />
        </div>}
        </>
    )
    
}

export default MemberHandicapTypes