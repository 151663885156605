import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormSelect from '../FormSelect';

function AddRoundForm() {
    return(
      <BasicAddForm>
        <FormRow>
            <FormTextInput width="4" name="round_num" placeholder="Round Number" label="* Round Number (e.g. 1)" required={true} feedbackType="invalid" feedbackText="Please enter a Round Number"/>  
            <FormTextInput width="4" name="counters" placeholder="Number of Counters" label="* Number of Counters" required={true} feedbackType="invalid" feedbackText="Please enter the number of counting shots"/> 
            <FormTextInput width="4" name="sighters" placeholder="Number of Sighters" label="* Number of Sighters" required={true} feedbackType="invalid" feedbackText="Please enter the number of sighters"/> 
        </FormRow>
      </BasicAddForm>
    )
}

export default AddRoundForm