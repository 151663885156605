import './App.css';
import { Routes, Route, useNavigate} from 'react-router-dom';
import Home from './pages/Home'
import Login from "./pages/Login"
import MembersHome from './pages/members/MembersHome';
import TrophiesHome from './pages/trophies/TrophiesHome';
import SettingsHome from './pages/settings/SettingsHome';
import MemberDetail from './pages/members/MemberDetail';
import MemberDetails from './pages/members/MemberDetails';
import MembersHandicaps from './pages/members/MembersHandicaps';
import MembersHandicapDetail from './pages/members/MembersHandicapDetail';
import MemberHandicapTypes from './pages/members/MemberHandicapTypes';
import MemberHandicapTypeDetail from './pages/members/MemberHandicapTypeDetail';
import HomeDetail from './pages/HomeDetail';
import { createContext} from 'react';
import Cookies from 'universal-cookie'
import { ProtectedRoute } from './components/ProtectedRoute';
import { AdminRoute } from './components/AdminRoute';
import { AuthProvider, useAuth } from './utils/hooks/useAuth';
import { EventsProvider } from './utils/hooks/useGetEvents';
import { MemberProvider } from './utils/hooks/useGetMember';
import GlobalProviders from './utils/hooks/GlobalProviders';
import GetMemberWrapper from './utils/hooks/wrappers/GetMemberWrapper';
import GetHandicapTypeWrapper from './utils/hooks/wrappers/GetHandicapTypeWrapper';
import GetEventWrapper from './utils/hooks/wrappers/GetEventWrapper';
import { HandicapTypeProvider } from './utils/hooks/useGetHandicapType';
import GetHandicapScoresWrapper from './utils/hooks/wrappers/GetHandicapScoresWrapper';
import { LoadingScreenProvider } from './utils/hooks/useLoadingScreen';

import { ClubDetailsHome, ClubDetails, GradeTemplates, Venues, Seasons } from './pages/clubdetails';
import { EventsHome, Events, EventDetail, PrizeTypes, PrizeTemplates} from './pages/events';

const cookies = new Cookies()

export const cookiesContext = createContext(cookies)

function App() {

  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={
            <LoadingScreenProvider>
            <GlobalProviders>
              <Routes>
                <Route path="/home" element={<ProtectedRoute><Home/></ProtectedRoute>} >
                  <Route index element={<HomeDetail />} />
                  <Route path="/home/members" element={<MemberProvider><MembersHome /></MemberProvider>}>
                    <Route index element={<MemberDetails />}>      
                    </Route>
                    <Route path="/home/members/:username" element={<GetMemberWrapper><MemberDetail/></GetMemberWrapper>} />
                    <Route path="/home/members/handicaps" element={<MembersHandicaps />}>
                    </Route>
                    <Route path="/home/members/:username/handicaps/:id" element={<GetMemberWrapper><GetHandicapScoresWrapper><MembersHandicapDetail /></GetHandicapScoresWrapper></GetMemberWrapper>} />
                    <Route path="/home/members/handicaptypes" element={<AdminRoute><MemberHandicapTypes /></AdminRoute>}>
                    </Route>
                    <Route path="/home/members/handicaptypes/:id" element={<AdminRoute><GetHandicapTypeWrapper><MemberHandicapTypeDetail /></GetHandicapTypeWrapper></AdminRoute>} />
                    
                  </Route>
                  <Route path="/home/clubdetails" element={<ClubDetailsHome />}>
                    <Route index element={<ClubDetails />} />
                    <Route path="/home/clubdetails/venues" element={<Venues />} />
                    <Route path="/home/clubdetails/seasons" element={<Seasons />} />
                    <Route path="/home/clubdetails/grades" element={<GradeTemplates />} />
                  </Route>
                  <Route path="/home/events" element={<EventsProvider><EventsHome /></EventsProvider>}>
                    <Route index element={<Events />} />
                    <Route path="/home/events/:id" element={<GetEventWrapper><EventDetail /></GetEventWrapper>} />
                    <Route path="/home/events/prizetypes" element={<PrizeTypes />} />
                    <Route path="/home/events/prizetemplates" element={<PrizeTemplates />} />
                  </Route>
                  <Route path="/home/trophies" element={<TrophiesHome />}>

                  </Route>
                </Route>
              </Routes>
              </GlobalProviders>
              </LoadingScreenProvider>
          } />
        </Routes>
      </AuthProvider>
    </>
    
  );

}



export default App;
