import Modal from "./Modal"
import OnClickButton from "./OnClickButton"
import "../pages/members/css/member-handicaps.css"
import { useModal } from "../utils/hooks/useModal"
import ALink from "./ALink"
import ContentMenu from "./ContentMenu"
import { useGetMember } from "../utils/hooks/useGetMember"
import { useGetHandicapScores } from "../utils/hooks/useGetHandicapScores"
import { Link } from "react-router-dom"

function HandicapDetailLayout({contentMenu, buttons = [], competitor, modalData = {}, deleteHandler, moveUpHandler, moveDownHandler})
{
    const {currentModalData, showModal,modalOpen, modalClose, modalSave} = useModal()
    const {member} = useGetMember()
    const {handicapScores} = useGetHandicapScores()

    const handleClick = (button) => {
        if(button.modal && button.modalDataKey)
        {
            modalOpen(modalData[button.modalDataKey])

        }
        else if(button.clickHandler)
        {
            button.clickHandler()
        }
    }

    

    return(
        <>
        <ContentMenu backUrl={contentMenu.backUrl} backText={contentMenu.backText} buttons={contentMenu.buttons}/>
        <div className="list-table-layout">
        { 
            buttons.map((button) => {
                return <OnClickButton key={button.key} text={button.text} onClickHandler={() => handleClick(button)} permission_max_level={button.permission_max_level}/>
            })
        }
        {competitor &&
            <div className="handicap-detail">
                <h3>Handicap Summary</h3>
                <table className="handicap-detail-summary">
                    <thead>
                        <tr>
                            <th>Member</th>
                            <th>Discipline</th>
                            <th>Exact Handicap</th>
                            <th>Handicap</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{member.user.full_name}</td>
                            <td>{competitor.handicap_type.name}</td>
                            <td className="handicap-bold">{competitor.handicap}</td>
                            <td className="handicap-bold">{competitor.shooting_handicap}</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Score History</h3>
                <table className="handicap-detail-history">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Event</th>
                            <th>Score</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                {
                    handicapScores && handicapScores.map((score) => {
                        return(
                            <tr key={score.id} className={score.counter ? "" : "no-counter"}>
                                <td width="64px" className="handicap-flag">{score.counter ? <img width="32px" src="/images/flag.png"></img> : ""}</td>
                                <td className="handicap-date">{(new Date(Date.parse(score.date))).toDateString()}</td>
                                <td className="handicap-event">{score.event_id ? <Link to={"/home/events/" + score.event_id}>{score.event.name}</Link> : score.event.name}</td>
                                <td className="handicap-score">{score.aggregate_score}</td>
                                <td className="handicap-delete"><ALink title="Delete" className={"fas"} href="" onClick={(event) => deleteHandler(event, score.id)} permission_max_level={2}>&#xf2ed;</ALink></td>
                                <td className="handicap-move">
                                    <ALink title="Move Up" className={"fas"} href="" onClick={(event) => moveUpHandler(event, score.id)} permission_max_level={2}>&#xf062;</ALink>
                                    &nbsp;
                                    <ALink title="Move Down" className={"fas"} href="" onClick={(event) => moveDownHandler(event, score.id)} permission_max_level={2}>&#xf063;</ALink>
                                </td>
                            </tr>
                        )
                    })
                    
                }
                    </tbody>
                </table>
            </div>
        }
        </div>
        <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
        </>
    )
}

export default HandicapDetailLayout