import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import Modal from "../../components/Modal"
import AddVenueForm from "../../components/forms/club/AddVenueForm"
import EditVenueForm from "../../components/forms/club/EditVenueForm"
import AddDistanceForm from "../../components/forms/club/AddDistanceForm"
import EditDistanceForm from "../../components/forms/club/EditDistanceForm"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import "./css/club-details.css"
import { useGetClubDetails } from "../../utils/hooks/useGetClubDetails"

const url = "/" + process.env.REACT_APP_CLUB + "/venues"

function Venues(){
    const [loading, setLoading] = useState(false)
    const {logout} = useAuth()

    const {venues, reloadVenues} = useGetClubDetails()

    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (modal, modalDataKey, id, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEditDistance = (event, modal, modalDataKey, id, venue_id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], venue_id + "/distances/" + id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }


    const addVenue = async (formData) => {
        let is_home = false

        if(formData.is_home && formData.is_home === "on")
        {
            is_home = true
        }

        let request_json = {
            name: formData.name,
            description: formData.description,
            is_home: is_home
        }

        let response = await postData(url, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadVenues()
            return response
        }
        

    }

    const updateVenue = async (formData) => {
        let is_home = false

        if(formData.is_home && formData.is_home === "on")
        {
            is_home = true
        }

        let request_json = {
            name: formData.name,
            description: formData.description,
            is_home: is_home
        }

        let response = await patchData(url + "/", formData.id, JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadVenues()
            return response
        }
    }

    const deleteVenue = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Venue"))
        {
            let response = await deleteData(url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                reloadVenues()
                return response
            }
        }
    }

    const addDistance = async (formData) => 
    {
        let request_json = {
            name: formData.name,
            short_name: formData.short_name
        }

        let response = await postData(url + "/" + formData.venue_id + "/distances", JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadVenues()
            return response
        }
    }

    const updateDistance = async (formData) => {
        let request_json = {
            name: formData.name,
            short_name: formData.short_name
        }

        let response = await patchData(url + "/" + formData.venue_id + "/distances/", formData.id, JSON.stringify(request_json))
        
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadVenues()
            return response
        }
    }

    const deleteDistance = async (event, venue_id, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Distance"))
        {
            let response = await deleteData(url + "/" + venue_id + "/distances/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                reloadVenues()
                return response
            }
        }
    }

    let modalData={
        "add": {
            title:"Add Venue", 
            data: <AddVenueForm />, 
            callback: (formData) => addVenue(formData)
        }, 
        "edit": {
            title:"Edit Venue", 
            data: <EditVenueForm />, 
            callback: (formData) => updateVenue(formData)
        },
        "addDistance": {
            title:"Add Distance", 
            data: <AddDistanceForm />, 
            callback: (formData) => addDistance(formData)
        }, 
        "editDistance": {
            title:"Edit Distance", 
            data: <EditDistanceForm />, 
            callback: (formData) => updateDistance(formData)
        },
    }


    return(
        <>
            {loading && <div>Loading...</div>}
            {venues &&
                <>
                <div className="list-table-layout">
                <h3>Venues</h3>
                <OnClickButton text="+ Add Venue" onClickHandler={() => handleClick(true, "add", null)} permission_max_level={2}/>
                {
                    venues.map((venue) => {
                        return(
                        <div key={venue.id} className="content-container venue-container">
                            <div className="content-container-head">
                                {venue.name}
                            </div>
                            <div className="content-container-row">
                                {venue.description}
                            </div>
                            <div className="content-container-row"><h5>Distances</h5></div>
                            <div className="content-table distance-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Short Name</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            venue.distances.map((distance) => {
                                                return(
                                                    <tr key={distance.id}>
                                                        <td>{distance.name}</td>
                                                        <td>{distance.short_name}</td>
                                                        <td><ALink className={"fas"} title={"Edit"} href={""} id={distance.id} onClick={(e) => handleClickEditDistance(e,true, "editDistance", distance.id, venue.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                                        <td><ALink className={"fas"} title={"Delete"} href={""} id={distance.id} onClick={(e) => handleClick(null, null, () => deleteDistance(e, venue.id, distance.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <OnClickButton text="Edit Venue" symbol="&#xf044;" symbolClassName={"fas button-padding-left"}  onClickHandler={() => handleClickEdit(true, "edit", venue.id, null)} permission_max_level={2}/>
                            <OnClickButton text="Delete Venue" symbol="&#xf2ed;" symbolClassName={"fas button-padding-left"}  onClickHandler={(e) => handleClick(null, null, () => deleteVenue(e, venue.id))} permission_max_level={2}/>
                            <OnClickButton text="Add Distance" symbol="+" symbolClassName={"fas button-padding-left"}  onClickHandler={(e) => handleClickEdit(true, "addDistance", venue.id, null)} permission_max_level={2}/>
                        </div>
                        )
                    })
                }
                </div>
                <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
                </>
            }
        </>
    )
}

export default Venues