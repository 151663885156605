import { createContext, useContext, useMemo, useState, useCallback} from "react";
import LoadingScreen from "../../components/LoadingScreen";

const LoadingScreenContext = createContext()

export const LoadingScreenProvider = ({ children }) => 
{
    
    const [showLoadingScreen, setShowLoadingScreen] = useState(false)
    const [loadingScreenError, setLoadingScreenError] = useState("")
    const [taskQueue, setTaskQueue] = useState([])

    const loadingScreenOpen = () => {

        setShowLoadingScreen(true)
    }

    const loadingScreenClose = () => {
        setShowLoadingScreen(false)
        setLoadingScreenError("")
        setTaskQueue([])
    }
    
    const addTask = useCallback((id, message, totalTasks=null) => {
        loadingScreenOpen()
        setTaskQueue(taskQueue => [...taskQueue, {id: id, message: message, tasks_done: 0, total_tasks: totalTasks, complete: false}])
    },[])

    const finishTask = useCallback((id) => {
        setTaskQueue(taskQueue => taskQueue.map(task =>
            task.id === id ? {...task, complete: true} : task
        ))

        if(allTasksDone())
            loadingScreenClose()
    },[])

    const updateTask = useCallback((id, tasks_done) => {
        setTaskQueue(taskQueue => taskQueue.map(task =>
            task.id === id ? {...task, tasks_done: tasks_done} : task
        ))
    }, [])

    function allTasksDone()
    {
        for(let i=0; i < taskQueue.length; i++)
        {
            let task = taskQueue[i]
            
            if(task.complete === false)
                return false
        }
        return true
    }
    
    const value = useMemo(
        () => ({
          addTask,
          finishTask,
          updateTask
        }),
        [addTask, finishTask, updateTask]
    );

    return <LoadingScreenContext.Provider value={value}>{children}<LoadingScreen show={showLoadingScreen} taskQueue={taskQueue}></LoadingScreen></LoadingScreenContext.Provider>;
}

export const useLoadingScreen = () => 
{
    return useContext(LoadingScreenContext);
};