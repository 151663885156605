import { formatDateTextDayName, formatDateText } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"
import EventDayLayout from "./EventDayLayout"
import { useGetEvent } from "../../../utils/hooks/useGetEvent"

function EventInfoLayout({topButtons=null, or_data=null, hcp_data=null, children})
{
    const {event} = useGetEvent()

    return(
        <>
        <div className="event-day-container">
            <div className="event-event-title-bar event-title-bar row">
                <div className="col">
                    <div className="event-event-title"><strong>Event:</strong> {event.name}</div>
                    <div className="event-event-title"><strong>Date:</strong> {formatDateTextDayName(event.start_date)}</div>
                </div>
                <div className="event-day-button event-title-bar-button col-lg-auto">
                    {topButtons && topButtons}
                </div>
            </div>
            {
                event &&
                event.days.map((day) =>
                    <EventDayLayout key={day.id} day={day} />
                )
            }
        </div>
        {children}
        {/* <div className="event-container-bar">
            <div className="event-container-date">
                {data && formatDateTextDayName(data.start_date)}
            </div>
            {topButtons && topButtons}
        </div>
        <div className="content-container">
            <div className="content-container-head">{data.name} : {getStatus(data.open, false)}</div>
            <div>
                <div className="content-container-row">
                    <div className="inline pr-15em"><strong>Is Multi-Day Event:</strong> {boolToYesNo(data.is_multi_day)}</div>
                    <div className="inline pr-15em"><strong>Multiple Matches per Day:</strong> {boolToYesNo(data.is_multi_match)}</div>
                </div>
                <div className="content-container-row">
                    <div className="inline pr-15em"><strong>Include in Club Championship:</strong> {boolToYesNo(data.is_championship)}</div>
                    <div className="inline pr-15em"><strong>Counts for Handicap:</strong> {boolToYesNo(data.is_handicapped)}</div>
                </div>
                <div className="content-container-row">
                    {
                        or_data &&
                        <div className="inline pr-15em"><strong>Off Rifle Prize Template:</strong> {getPrizeTemplateWithId(data.or_prize_template_id, or_data).name}</div>
                    }
                    {
                        hcp_data &&
                        <div className="inline pr-15em"><strong>Handicap Prize Template:</strong> {getPrizeTemplateWithId(data.h_prize_template_id, hcp_data).name}</div>
                    }
                </div>
            </div>
        </div> */}
        </>
    )

}

export default EventInfoLayout