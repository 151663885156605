import { precisionRound } from "./precisionRound"

export function getEventCompetitor(event_competitor_id, eventCompetitors)
{
    for(let i=0; i < eventCompetitors.length; i++)
    {
        let eventCompetitor = eventCompetitors[i]
        if(eventCompetitor.id === event_competitor_id)
        {
            return eventCompetitor
        }
    }
    return null
}

export function getClass(class_id, classes)
{
    for(let i=0; i < classes.length; i++)
    {
        let _class = classes[i]
        if(_class.id === class_id)
        {
            return _class
        }
    }
    return null
}

export function getGrade(grade_id, grades)
{
    for(let i=0; i < grades.length; i++)
    {
        let grade = grades[i]
        if(grade.id === grade_id)
        {
            return grade
        }
    }
    return null
}

export function getEventCompetitorWithCompetitorId(competitor_id, eventCompetitors)
{
    for(let i=0; i < eventCompetitors.length; i++)
    {
        let eventCompetitor = eventCompetitors[i]
        if(eventCompetitor.competitor_id === competitor_id)
        {
            return eventCompetitor
        }
    }
    return null
}

export function getEventResultWithEventCompetitorId(event_competitor_id, eventResults)
{
    for(let i=0; i < eventResults.length; i++)
    {
        let eventResult = eventResults[i]
        if(eventResult.event_competitor_id === event_competitor_id)
        {
            return eventResult
        }
    }
    return null
}

export function getDayWithId(day_id, days)
{
    for(let i=0; i < days.length; i++)
        {
            let day = days[i]
            if(day.id === day_id)
            {
                return day
            }
        }
        return null
}

export function getEventDayResult(event_competitor_id, event_result_id, dayResults)
{
    for(let i=0; i < dayResults.length; i++)
    {
        let dayResult = dayResults[i]
        if(dayResult.event_competitor_id === event_competitor_id && dayResult.event_result_id == event_result_id)
        {
            return dayResult
        }
    }
    return null
}

export function getEventDayResult2(event_competitor_id, dayResults)
{
    for(let i=0; i < dayResults.length; i++)
    {
        let dayResult = dayResults[i]
        if(dayResult.event_competitor_id === event_competitor_id)
        {
            return dayResult
        }
    }
    return null
}

export function getEventMatchResult(event_competitor_id, matchResults)
{
    for(let i=0; i < matchResults.length; i++)
    {
        let matchResult = matchResults[i]
        if(matchResult.event_competitor_id === event_competitor_id)
        {
            return matchResult
        }
    }
    return null
}

export function getEventRoundResult(event_competitor_id, roundResults)
{
    for(let i=0; i < roundResults.length; i++)
    {
        let roundResult = roundResults[i]
        if(roundResult.event_competitor_id === event_competitor_id)
        {
            return roundResult
        }
    }
    return null
}

export function getAllRoundResultsInEvent(event_competitor_id, event)
{
    let retArr = []
    for(let i=0; i < event.days.length; i++)
    {
        let day = event.days[i]
        for(let j=0; j < day.matches.length; j++)
        {
            let match = day.matches[j]
            let roundResults = getAllRoundResults(event_competitor_id, match.rounds, day.date)
            
            retArr = retArr.concat(roundResults)
        }
    }
    return retArr
}

export function hasMatchResultInDay(event_competitor_id, matches)
{
    for(let i=0; i < matches.length; i++)
    {
        let match = matches[i]
        if(getEventMatchResult(event_competitor_id, match.match_results))
        {
            return true
        }
    }
    return false
}

export function hasDayResultInEvent(event_competitor_id, days)
{
    for(let i=0; i < days.length; i++)
    {
        let day = days[i]
        if(getEventDayResult2(event_competitor_id, day.day_results))
        {
            return true
        }
    }
    return false
}

export function getAllRoundResults(event_competitor_id, rounds, date=null)
{
    let retArr = []
    for(let i = 0; i < rounds.length; i++)
    {
        let round = rounds[i]
        for(let j = 0; j < round.round_results.length; j++)
        {
            let round_result = round.round_results[j]
            if(round_result.event_competitor_id === event_competitor_id)
            {
                if(date)
                    retArr.push({date: date, round_num: round.round_num, num_sighters: round.sighters, num_counters: round.counters, result: round_result})
                else
                    retArr.push({round_num: round.round_num, num_sighters: round.sighters, num_counters: round.counters, result: round_result})
            }
        }
    }
    return retArr
}

export function getCounterShotArrayFromResult(round_result)
{
    let shotArray = new Array(round_result.num_counters)
    for(let i=0; i < shotArray.length; i++)
    {
        shotArray[i] = round_result.result.counters.charAt(i)
    }
    return shotArray
}

export function getSighterShotArrayFromResult(round_result)
{
    let shotArray = new Array(round_result.num_sighters)
    for(let i=0; i < shotArray.length; i++)
    {
        shotArray[i] = round_result.result.sighters.charAt(i)
    }
    return shotArray
}

export function setScoreAtIndex(counterString, index, score)
{
    let retStr = counterString
    if(index < counterString.length)
        retStr = setCharAt(counterString, index, score.toUpperCase())
    else if(index == counterString.length)
    {
        retStr = retStr.concat(score.toUpperCase())
    }
    else
    {
        for(let i=counterString.length; i < index; i++)
        {
            retStr = retStr.concat("0")
        }
        retStr = retStr.concat(score.toUpperCase())
    }
    return retStr
}

export function setCharAt(str,index,chr) {
    if(index > str.length-1) return str;
    return str.substring(0,index) + chr + str.substring(index+1);
}

export function getAllowedInput(_class)
{
    let allowedInput = ["0", "1", "2", "3", "4", "5"]
    allowedInput.push(_class.central_mark)
    allowedInput.push(_class.super_mark)
    return allowedInput
}

export function getScoreMapArray(_class)
{
    let scoreMap = {"0": 0.0, "1": 1.0, "2": 2.0, "3": 3.0, "4": 4.0, "5": 5.0}
    scoreMap[_class.central_mark] = _class.central_value
    scoreMap[_class.super_mark] = _class.super_value
    return scoreMap
}

export function addScore(scoreArray, scoreMap)
{
    let total = 0.0
    for(let i = 0; i < scoreArray.length; i++)
    {
        let score = scoreArray[i]
        if(scoreMap[score])
            total += scoreMap[score]
        else
            total += 0.0
    }
    return precisionRound(total, 2)
}

export function calculateAggregate(results, scoreMap)
{
    let aggregate = 0.0
    for(let i=0; i < results.length; i++)
    {
        let result = results[i]
        let shotArray = getCounterShotArrayFromResult(result)
        aggregate += addScore(shotArray, scoreMap)
    }
    return precisionRound(aggregate, 2)
}

export function isSighterConverted(index, num_converted)
{
    if(index === 0)
    {
        if(num_converted === 2)
            return true
    }
    if(index === 1)
    {
        if(num_converted > 0)
            return true
    }
    return false
}

export function replaceCountersWithSighters(counterString, sighterString, num)
{
    let retStr = counterString
    for(let i = 0; i < num; i++)
    {
        let replaceWith = sighterString.charAt(sighterString.length - num + i)
        if(counterString.length >= num)
            retStr = setCharAt(retStr, i, replaceWith)
        else
            retStr = retStr.concat(replaceWith)
    }
    return retStr
}