import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";
import { useLoadingScreen } from "./useLoadingScreen";

const ClubDetailsContext = createContext()

export const ClubDetailsProvider = ({ children }) => 
{
    const {addTask, finishTask} = useLoadingScreen()

    const [clubDetailsRefresh, setClubDetailsRefresh] = useState(0)
    const [clubDetails, setClubDetails] = useState(null) 

    const [venuesRefresh, setVenuesRefresh] = useState(0)
    const [venues, setVenues] = useState([]) 
    
    const [seasonsRefresh, setSeasonsRefresh] = useState(0)
    const [seasons, setSeasons] = useState([]) 
    
    const [gradeTemplatesRefresh, setGradeTemplatesRefresh] = useState(0)
    const [grade_templates, setGradeTemplates] = useState([]) 

    const getClubDetails = useMemo(() => async () => {
        addTask("clubdetails", "Loading Club Details")
        setClubDetails((await doGet("/" + process.env.REACT_APP_CLUB)).data)
        finishTask("clubdetails")
    }, [clubDetailsRefresh])

    const getSeasonsData = useMemo(() => async () => {
        addTask("seasons", "Loading Seasons")
        setSeasons((await doGet("/" + process.env.REACT_APP_CLUB + "/seasons")).data)
        finishTask("seasons")
    }, [seasonsRefresh])

    const getVenuesData = useMemo(() => async () => {
        addTask("venues", "Loading Venues")
        setVenues((await doGet("/" + process.env.REACT_APP_CLUB + "/venues")).data)
        finishTask("venues")
    }, [venuesRefresh])

    const getGradeTemplatesData = useMemo(() => async () => {
        addTask("gradetemplates", "Loading Grade Templates")
        setGradeTemplates((await doGet("/" + process.env.REACT_APP_CLUB + "/gradetemplates")).data)
        finishTask("gradetemplates")
    }, [gradeTemplatesRefresh])

    useEffect(() => {
        getClubDetails()
    },[getClubDetails]
);

    useEffect(() => {
            getSeasonsData()
        },[getSeasonsData]
    );

    useEffect(() => {
            getVenuesData()
        },[getVenuesData]
    );

    useEffect(() => {
            getGradeTemplatesData()
        },[getGradeTemplatesData]
    );

    function reloadClubDetails()
    {
        setClubDetailsRefresh(clubDetailsRefresh + 1)
    }

    function reloadSeasons()
    {
        setSeasonsRefresh(seasonsRefresh + 1)
    }

    function reloadVenues()
    {
        setVenuesRefresh(venuesRefresh + 1)
    }

    function reloadGradeTemplates()
    {
        setGradeTemplatesRefresh(gradeTemplatesRefresh + 1)
    }

    return <ClubDetailsContext.Provider value={{clubDetails, venues, seasons, grade_templates, reloadClubDetails, reloadSeasons, reloadVenues, reloadGradeTemplates}}>{children}</ClubDetailsContext.Provider>;
}

export const useGetClubDetails = () => 
{
    return useContext(ClubDetailsContext);
};