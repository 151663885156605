import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import AddEventForm from "../../components/forms/events/AddEventForm"
import EditEventForm from "../../components/forms/events/EditEventForm"
import ListTableLayout from "../../components/layouts/ListTableLayout"
import BasicTable from "../../components/tables/BasicTable"
import BasicHead from "../../components/tables/BasicHead"
import BasicBody from "../../components/tables/BasicBody"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import { formatDateTextShort } from "../../utils/formatDate"
import "./css/events.css"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getProcessButtonText, processEvent} from "../../utils/eventUtils"
import { useGetClubDetails } from "../../utils/hooks/useGetClubDetails"
import { useGetEvents } from "../../utils/hooks/useGetEvents"


const url = "/" + process.env.REACT_APP_CLUB + "/events"
const or_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/offrifle"
const hcp_url = "/" + process.env.REACT_APP_CLUB + "/events/prizetemplates/handicap"

const router_url = "/home/events/"

function Events() {
    const [loading, setLoading] = useState(false)

    const {logout} = useAuth()

    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()
    const {events, off_rifle_templates, handicap_templates, reloadEvents, toggleEventsDescending, orderEventsBy} = useGetEvents()
    const {seasons, venues, grade_templates} = useGetClubDetails()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleOrder = (event, order_by) => {
        event.preventDefault()
        orderEventsBy(order_by)
        toggleEventsDescending()
    }

    const handleClickOpenClose = async (event, id, currentState) => {
        event.preventDefault()
        let new_state = ""
        if(currentState === true)
        {
            new_state = "close"
        }
        else
        {
            new_state = "open"
        }

        let response = await patchData(url + "/" + id + "/" + new_state, "")
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvents()
            return response
        }
    }


    const addEvent = async (formData) => {
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = null
        let is_multi_day = false
        let is_multi_match = false
        let is_championship = false
        let is_handicapped = false
        let grade_ids = (formData.selected_grades).split(",")

        let grades_array = []
        for(let i=0; i < grade_ids.length; i++)
        {
            let grade_id = grade_ids[i]
            for(let j=0; j < grade_templates.length; j++)
            {
                if(grade_templates[j].id == grade_id)
                {
                    grades_array.push(grade_templates[j])
                } 
            }
        }


        if(formData.end_date)
            end_date = new Date(formData.end_date).toISOString()
        else
            end_date = new Date(formData.start_date).toISOString()

        if(formData.is_multi_day)
            is_multi_day = true

        if(formData.is_multi_match)
            is_multi_match = true

        if(formData.is_championship)
            is_championship = true

        if(formData.is_handicapped)
            is_handicapped = true


        let request_json = {
            name: formData.name,
            season_id: parseInt(formData.season_id),
            venue_id: parseInt(formData.venue_id),
            distance_id: parseInt(formData.distance_id),
            open: false,
            processed: false,
            is_multi_day: is_multi_day,
            is_multi_match: is_multi_match,
            start_date: start_date,
            end_date: end_date,
            grades: JSON.stringify(grades_array),
            is_championship: is_championship,
            is_handicapped: is_handicapped,
            or_prize_template_id: parseInt(formData.or_prize_template_id),
            h_prize_template_id: parseInt(formData.h_prize_template_id),
            prizes: "[]"
        }

        let response = await postData(url, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvents()
            return response
        }
        

    }

    const updateEvent = async (formData) => {
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = null
        let is_multi_day = false
        let is_multi_match = false
        let is_championship = false
        let is_handicapped = false
        let grade_ids = (formData.selected_grades).split(",")

        let grades_array = []
        for(let i=0; i < grade_ids.length; i++)
        {
            let grade_id = grade_ids[i]
            for(let j=0; j < grade_templates.length; j++)
            {
                if(grade_templates[j].id == grade_id)
                {
                    grades_array.push(grade_templates[j])
                } 
            }
        }


        if(formData.end_date)
            end_date = new Date(formData.end_date).toISOString()
        else
            end_date = new Date(formData.start_date).toISOString()

        if(formData.is_multi_day)
            is_multi_day = true

        if(formData.is_multi_match)
            is_multi_match = true

        if(formData.is_championship)
            is_championship = true

        if(formData.is_handicapped)
            is_handicapped = true


        let request_json = {
            name: formData.name,
            is_multi_day: is_multi_day,
            is_multi_match: is_multi_match,
            start_date: start_date,
            end_date: end_date,
            season_id: parseInt(formData.season_id),
            grades: JSON.stringify(grades_array),
            is_championship: is_championship,
            is_handicapped: is_handicapped,
            or_prize_template_id: parseInt(formData.or_prize_template_id),
            h_prize_template_id: parseInt(formData.h_prize_template_id),
        }


        let response = await patchData(url + "/", formData.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvents()
            return response
        }
    }

    const deleteEvent = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Event"))
        {
            let response = await deleteData(url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                //setData(data.filter(element => element.id !== id))
                reloadEvents()
                return response
            }
        }
    }



    let modalData={
        "add": {
            title:"Add Event", 
            data: <AddEventForm off_rifle_prize_templates={off_rifle_templates} handicap_prize_templates={handicap_templates} seasons={seasons} venues={venues}/>, 
            callback: (formData) => addEvent(formData)
        }, 
        "edit": {
            title:"Edit Event", 
            data: <EditEventForm off_rifle_prize_templates={off_rifle_templates} handicap_prize_templates={handicap_templates} seasons={seasons} />, 
            callback: (formData) => updateEvent(formData)
        },
    }

    let topButtons = [
        <OnClickButton key="add" text="+ Add Event" onClickHandler={() => handleClick(true, "add", null)} permission_max_level={2}/>
    ]

    

    return(
        <>
            {loading && <div>Loading...</div>}
            {events &&
                <ListTableLayout title="Events" topButtons={topButtons}>
                    <BasicTable edit={true} del={true} >
                        <BasicHead>
                            <th><ALink title="Order By Name" href={""} onClick={(e) => handleOrder(e,"name")}>Name</ALink></th>
                            <th><ALink title="Order By Start Date" href={""} onClick={(e) => handleOrder(e,"start_date")}>Date</ALink></th>
                            <th><ALink title="Order By Status" href={""} onClick={(e) => handleOrder(e,"open")}>Status</ALink></th>
                            <th><ALink title="Order By Processed" href={""} onClick={(e) => handleOrder(e,"processed")}>Processed</ALink></th>
                            <th></th>
                        </BasicHead>
                        <BasicBody>
                            {
                                events.map((event) => {
                                    return(
                                        <tr key={event.id}>
                                            <td><ALink title="Event Details" href={router_url + event.id}>{event.name}</ALink></td>
                                            <td>{event.start_date === event.end_date ? formatDateTextShort(event.start_date) : formatDateTextShort(event.start_date) + " - " + formatDateTextShort(event.end_date)}</td>
                                            <td>{getStatus(event.open)}</td>
                                            <td>{boolToYesNo(event.processed)}</td>
                                            <td>{event.processed ? "" : <ALink className={""} title={getOpenCloseButtonText(event.open)} href={""} id={event.id} onClick={(e) => handleClickOpenClose(e, event.id, event.open)} permission_max_level={2}>{getOpenCloseButtonText(event.open)}</ALink>}</td>
                                            <td><ALink className={"fas"} title={"Edit"} href={""} id={event.id} onClick={(e) => handleClickEdit(e,true, "edit", event.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                            <td><ALink className={"fas"} title={"Delete"} href={""} id={event.id} onClick={(e) => handleClick(null, null, () => deleteEvent(e, event.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                        </tr>
                                    )
                                })
                            }
                        </BasicBody>
                    </BasicTable>
                    <strong><i>Please Note: To open an Event that has been processed you must unprocess the event first.</i></strong>
                </ListTableLayout>
            }
        </>
    )
}

export default Events