import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormSelect from '../FormSelect';
import FormDateInput from '../FormDateInput';
import { useGetClubDetails } from '../../../utils/hooks/useGetClubDetails';

function AddDayForm({}) {
    const {venues} = useGetClubDetails()
    return(
      <BasicAddForm>
        <FormRow>
            <FormTextInput width="4" name="name" placeholder="Day Name" label="* Day Name" required={true} feedbackType="invalid" feedbackText="Please enter a Day Name"/>  
            <FormSelect width={3} name="venue_id" label="* Venue" placeholder="Venue" required={true} feedbackType="invalid" feedbackText="Please Select Venue">
                {
                    venues && venues.map((venue) => (
                        <option key={venue.id} value={venue.id}>{venue.name}</option>
                    ))
                }
            </FormSelect>
            <FormDateInput width="4" name="date" placeholder="Date" label="* Date" labelId="date_label" required={true} feedbackType="invalid" feedbackText="Please enter a Date"/>
        </FormRow>
      </BasicAddForm>
    )
}

export default AddDayForm