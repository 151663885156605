import { Link } from "react-router-dom"

function ContentMenu({backUrl, backText, buttons=[]}) {
    return (
        <>
            <div className="content-menu-nav">
                {backUrl &&
                    <div className="p-3 nav-back">
                        <Link to={backUrl}>&#8592; {backText}</Link>
                    </div>
                }
                <div className="content-menu-nav-right">
                    {
                        buttons.map(button => (
                            <div className="content-menu-nav-item" key={button.id}>
                                {button.content}
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default ContentMenu