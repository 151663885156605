import { formatDateTextDayName } from "../../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../../utils/eventUtils"
import EventMatchLayout from "./EventMatchLayout"
import OnClickButton from "../../OnClickButton"
import AddMatchForm from "../../forms/events/AddMatchForm"
import EditDayForm from "../../forms/events/EditDayForm"
import { useModal } from "../../../utils/hooks/useModal"
import { postData } from "../../../api/postData"
import { patchData } from "../../../api/patchData"
import { useAuth } from "../../../utils/hooks/useAuth"
import { useGetEvent } from "../../../utils/hooks/useGetEvent"
import { deleteData } from "../../../api/deleteData"

function EventDayLayout({day=null, children})
{
    const url = "/" + process.env.REACT_APP_CLUB + "/events/" + day.event_id + "/days/" + day.id + "/matches"
    const days_url = "/" + process.env.REACT_APP_CLUB + "/events/" + day.event_id + "/days/"
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()
    const {logout} = useAuth()
    const {event, reloadEvent} = useGetEvent()

    const handleClickAdd = (event, modal, modalDataKey, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    let modalData={
        "add": {
            title:"Add Match", 
            data: <AddMatchForm distances={day.venue.distances} />, 
            callback: (formData) => addMatch(formData)
        },
        "edit": {
            title:"Edit Day", 
            data: <EditDayForm />, 
            callback: (formData) => editDay(formData)
        },
    }

    const editDay = async (formData) => {
        let date = new Date(formData.date).toISOString()

        let request_json = {
            name: formData.name,
            venue_id: parseInt(formData.venue_id),
            date: date,
            or_prize_template_id: event.or_prize_template_id,
            h_prize_template_id: event.h_prize_template_id,
        }


        let response = await patchData(days_url, formData.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvent()
            return response
        }
    }

    const addMatch = async (formData) => {
        let request_json = {
            name: formData.name,
            distance_id: parseInt(formData.distance_id),
            or_prize_template_id: parseInt(day.or_prize_template_id),
            h_prize_template_id: parseInt(day.h_prize_template_id),
            prizes: "[]"
        }


        let response = await postData(url, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvent()
            return response
        }
    }

    const deleteDay = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Day"))
        {
            let response = await deleteData(days_url, id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                //setData(data.filter(element => element.id !== id))
                reloadEvent()
                return response
            }
        }
    }

    return(
        <>
        <div className="event-day-container">
            <div className="event-day-title-bar event-title-bar row">
                <div className="col">
                    <div className="event-day-title"><strong>Day:</strong> {day.name}</div>
                    <div className="event-day-title"><strong>Venue:</strong> {day.venue.name}</div>
                </div>
                <div className="event-day-button event-title-bar-button col-lg-auto">
                    <OnClickButton text="Edit Day" onClickHandler={(e) => handleClickEdit(e,true, "edit", day.id, null)} permission_max_level={2}/>
                    <OnClickButton text="Delete Day" onClickHandler={(e) => deleteDay(e, day.id)} permission_max_level={2}/>
                    <OnClickButton text="Add Match" onClickHandler={(e) => handleClickAdd(e,true, "add", null)} permission_max_level={2}/>
                </div>
            </div>
            {
                day.matches.map((match) => 
                    <EventMatchLayout key={match.id} match={match} />
                )
            }
        </div>
        {children}
        </>
    )

}

export default EventDayLayout