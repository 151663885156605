import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";

const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => 
{
    const [userRolesRefresh, setUserRolesRefresh] = useState(0)
    const [userRoles, setUserRoles] = useState(null)
    
    const [versionRefresh, setVersionRefresh] = useState(0)
    const [version, setVersion] = useState("0.0.0")

    const [classesRefresh, setClassesRefresh] = useState(0)
    const [classes, setClasses] = useState(null)
    

    const getUserRoleData = useMemo(() => async () => {
        setUserRoles((await doGet("/users/roles")).data)
    }, [userRolesRefresh])

    const getVersionData = useMemo(() => async () => {
        setVersion((await doGet("/db/version")).data)
    }, [versionRefresh])

    const getClassData = useMemo(() => async () => {
        setClasses((await doGet("/classes/")).data)
    }, [classesRefresh])

    useEffect(() => {
            getUserRoleData()
        },[getUserRoleData]
    );

    useEffect(() => {
        getVersionData()
    },[getVersionData]
    );

    useEffect(() => {
        getClassData()
    },[getClassData]
    );


    function reloadUserRoles()
    {
        setUserRolesRefresh(userRolesRefresh + 1)
    }

    function reloadVersion()
    {
        setVersionRefresh(versionRefresh + 1)
    }

    function reloadClasses()
    {
        setClassesRefresh(classesRefresh + 1)
    }


    return <GlobalContext.Provider value={{userRoles, version, classes, reloadUserRoles, reloadVersion, reloadClasses}}>{children}</GlobalContext.Provider>;
}

export const useGetGlobal = () => 
{
    return useContext(GlobalContext);
};