import { useEffect, useState } from "react"
import { api_get } from "./api"

export async function doGet(url){
    let loading = false
    let error = null
    let data = null


    try
    {
        loading=true
        const response = await api_get(url)
        data=response.data
    }
    catch(_error)
    {
        error = _error
    }
    finally
    {
        loading=false
    }
    return {data, error, loading}
}