import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";

const MembersContext = createContext()

export const MembersProvider = ({ children }) => 
{
    const [membersRefresh, setMembersRefresh] = useState(0)
    const [members, setMembers] = useState([])
    
    const [disciplinesRefresh, setDisciplinesRefresh] = useState(0)
    const [disciplines, setDisciplines] = useState([])
    

    const getMemberData = useMemo(() => async () => {
        setMembers((await doGet("/" + process.env.REACT_APP_CLUB + "/members/")).data)
    }, [membersRefresh])

    const getDisciplineData = useMemo(() => async () => {
        setDisciplines((await doGet("/" + process.env.REACT_APP_CLUB + "/members/handicaptypes")).data)
    }, [disciplinesRefresh])


    useEffect(() => {
            getMemberData()
        },[getMemberData]
    );

    useEffect(() => {
        getDisciplineData()
    },[getDisciplineData]
    );


    function reloadMembers()
    {
        setMembersRefresh(membersRefresh + 1)
    }

    function reloadDisciplines()
    {
        setDisciplinesRefresh(disciplinesRefresh + 1)
    }


    return <MembersContext.Provider value={{members, disciplines, reloadMembers, reloadDisciplines}}>{children}</MembersContext.Provider>;
}

export const useGetMembers = () => 
{
    return useContext(MembersContext);
};