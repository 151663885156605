import {React, useContext} from "react";
import OnClickButton from "../../OnClickButton";
import ALink from "../../ALink"
import { useAuth } from "../../../utils/hooks/useAuth";
import { useModal } from "../../../utils/hooks/useModal";
import { deleteData } from "../../../api/deleteData";
import { Link } from "react-router-dom";

const url = "/" + process.env.REACT_APP_CLUB + "/members/"

function MemberDetailContent({data, modalData, disciplineDeleteHandler}) {
    const {modalOpen} = useModal()
    const {user, logout} = useAuth()

    const handleEdit = (e, id) => {
        e.preventDefault();
        modalOpen(modalData["edit"], id)
    }

    const handleAddDiscipline = (e) => {
        e.preventDefault();
        modalOpen(modalData["add_discipline"])
    }

    const handleEditDiscipline = (e, id) => {
        e.preventDefault();
        modalOpen(modalData["edit_discipline"], id)
    }

    

    return ( 
    <>
    <div className="main-body">
        <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
            <div className="card">
                <div className="card-body">
                <div className="align-items-center text-center">
                    <img src="/images/MRC_Logo_Transparent_600.png" alt="Admin" className="rounded-circle" width="150"/>
                    <div className="mt-3">
                    <h4>{data.user.full_name}</h4>
                    <p className="text-secondary mb-1">{data.user.role.display_name}</p>
                    </div>
                </div>
                </div>

                <div className="card-body">
                <hr/>
                <div className="row">
                    <div className="col-sm-3">
                    <h6 className="mb-0">Email</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                        {data.user.email}
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-sm-3">
                    <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                        {data.phone}
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                        {data.address1}{(data.address2 ? ", " + data.address2 : "")}{(data.suburb ? ", " + data.suburb : "")}{(data.state ? " " + data.state : "")}{(data.postcode ? " " + data.postcode : "")}
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-sm-12">
                    <OnClickButton text="Edit" id={data.user.username} onClickHandler={(e) => handleEdit(e, data.user.username)} permission_max_level={2} permission_id_override={user.username}/>
                    </div>
                </div>
                </div>
            </div>
            <div className="card mt-3">
                <table className="discipline-table">
                    <thead>
                    <tr>
                        <th className="p-2"><h5>Disciplines (Target ID)</h5></th>
                        <th className="p-2"><h5>HCP</h5></th>
                        {user.role.level < 3 ? <><th></th><th></th></> : <></> }
                    </tr>
                    </thead>
                    <tbody>
                        {data && 
                            data.competitors.map((competitor) => {
                                return(
                                <tr key={competitor.id}>
                                    <td className="p-2"><h6><Link to={"../" + data.state_sid + "/handicaps/" + competitor.id +"/"} relative="path">{competitor.handicap_type.name} ({competitor.e_target_id})</Link></h6></td>
                                    <td className="p-2"><strong>{competitor.shooting_handicap != null ? competitor.shooting_handicap : "INV"}</strong></td>
                                    {
                                        user.role.level < 3 ?
                                        <>
                                            <td className="p-2"><ALink title="Edit" href="" className="fas" onClick={(e) => handleEditDiscipline(e, competitor.id)}>&#xf044;</ALink></td>
                                            <td className="p-2"><ALink title="Delete" href="" className="fas" onClick={(e) => disciplineDeleteHandler(e, competitor.id)}>&#xf2ed;</ALink></td>
                                        </>
                                        :
                                        <></>
                                    }
                                </tr>
                                )
                            })
                        }   
                    </tbody>
                </table>
                <div className="col-sm-12">
                    <OnClickButton text="Add Discipline" onClickHandler={(e) => handleAddDiscipline(e)} permission_max_level={2}/>
                </div>
            </div>
            </div>
            <div className="col-md-8">
            <div className="card mb-3">
                <div className="recent-results-title">
                    <h3 className="p-3">Recent Results</h3>
                </div>
                <div className="card-body">
                <div className="row">
                    <div className="col-sm-3">
                    <h6 className="mb-0">Coming Soon</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                    
                    </div>
                </div>
                
                </div>
            </div>
            </div>
        </div>

    </div>
    </>
    )
}

export default MemberDetailContent