import { useModal } from "../../utils/hooks/useModal"
import { formatDateTextDayName } from "../../utils/formatDate"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId} from "../../utils/eventUtils"
import Modal from "../Modal"
import EventInfoLayout from "./event/EventInfoLayout"
import EventDayLayout from "./event/EventDayLayout"
import { createContext } from "react"
import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useGetGlobal } from "../../utils/hooks/useGetGlobal"
import { useGetEvent } from "../../utils/hooks/useGetEvent"

function EventLayout({topButtons=null, children})
{
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    return(
        <>
        <div className="event-container">
            <EventInfoLayout topButtons={topButtons}/>
        </div>
        <Modal show={showModal} handleClose={modalClose} title={currentModalData ? currentModalData.title : ""} >{currentModalData ? currentModalData.data : ""}</Modal>
        </>
    )

}

export default EventLayout