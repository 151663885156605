import BasicAddForm from '../BasicAddForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormSelect from '../FormSelect';

function AddMatchForm({distances}) {
    return(
      <BasicAddForm>
        <FormRow>
            <FormTextInput width="8" name="name" placeholder="Match Name" label="* Match Name" required={true} feedbackType="invalid" feedbackText="Please enter a Match Name"/>  
            <FormSelect width={3} name="distance_id" label="* Distance" placeholder="Distance" required={true} feedbackType="invalid" feedbackText="Please Select Distance" defaultValue="0">
                {
                    distances && distances.map((distance) => (
                        <option key={distance.id} value={distance.id}>{distance.name}</option>
                    ))
                }
            </FormSelect>
        </FormRow>
      </BasicAddForm>
    )
}

export default AddMatchForm