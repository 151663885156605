const LoadingScreen = ({show, taskQueue}) => {
    const showHideClassName = show ? "display-show" : "display-hide"

    return(
        <>
            <div className={"list-layout-modal modal " + showHideClassName} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Please Wait</h5>
                </div>
                <div className="modal-body">
                    {
                        taskQueue.map((task) => {
                            return <div>{task.message} {task.total_tasks ? "(" + task.tasks_done + " of " + task.total_tasks + ")" : "" } {task.complete ? " Done" : ""}</div>
                        })
                    }
                </div>
                <div id="modal-error" className="modal-error">
                    
                </div>

                </div>
            </div>
            </div>
        </>
    )
}

export default LoadingScreen