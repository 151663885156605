import { useState } from "react"
import { useAuth } from "../../../utils/hooks/useAuth"
import { useModal } from "../../../utils/hooks/useModal"
import AddCompetitorsForm from "../../forms/events/AddCompetitorsForm"
import EditCompetitorHcpForm from "../../forms/events/EditCompetitorHcpForm"
import OnClickButton from "../../OnClickButton"
import { postData } from "../../../api/postData"
import { useContext } from "react"
import { groupMemberDisciplines } from "../../../utils/groupMemberDisciplines"
import { getCompetitor } from "../../../utils/getCompetitor"
import { getEventCompetitor, getGrade, getEventCompetitorWithCompetitorId, getEventResultWithEventCompetitorId , getDayWithId, getEventDayResult, getEventDayResult2, getEventMatchResult, getEventRoundResult, hasMatchResultInDay, hasDayResultInEvent} from "../../../utils/eventCompetitorUtils"
import { deleteData } from "../../../api/deleteData"
import ALink from "../../ALink"
import BasicTable from "../../tables/BasicTable"
import BasicHead from "../../tables/BasicHead"
import BasicBody from "../../tables/BasicBody"
import { useGetData } from "../../../api/hooks/useGetData"
import { patchData } from "../../../api/patchData"
import { useGetMembers } from "../../../utils/hooks/useGetMembers"
import { useGetEvent } from "../../../utils/hooks/useGetEvent"

function EventCompetitorTab({match_id, day_id, event_id, children})
{
    let url = "/" + process.env.REACT_APP_CLUB + "/events/"
    
    let handicap_multiplier = 2

    const {logout} = useAuth()
    const {event, reloadEvent, getMatch} = useGetEvent()
    let match = getMatch(match_id)

    let match_url = url + event_id + "/days/" + day_id + "/matches/" + match_id
    
    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()
    const {members, disciplines} = useGetMembers()


    const handleClickAddCompetitor = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, event_id, day_id, match_id, name, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalData[modalDataKey].data = <EditCompetitorHcpForm event_id={event_id} day_id={day_id} match_id={match_id} name={name}/>
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    let modalData={
        "add_competitor": {
            title:"Add Competitors", 
            data: <AddCompetitorsForm members={members} grades={event.grades}/>,
            callback: (formData) => addCompetitors(formData)
        },
        "edit_hcp": {
            title:"Edit Handicap", 
            data: <EditCompetitorHcpForm />,
            callback: (formData) => updateCompetitor(formData)
        },
    }
    


    const addCompetitors = async (formData) => {
        let formArray = groupMemberDisciplines(formData)

        let last_response = null
        for(let i = 0; i < formArray.length; i++)
        {
            let entry = formArray[i]
            let response = {data: getEventCompetitorWithCompetitorId(parseInt(entry.competitor_id), event.competitors)}
            if(response.data === null)
            {
                let request_json = {
                    is_visitor: false,
                    competitor_id: parseInt(entry.competitor_id),
                    visitor_id: null,
                    handicap: getCompetitor(parseInt(entry.competitor_id), members).shooting_handicap,
                    grade_id: parseInt(entry.grade_id),
                    prize_eligible: true
                }
                response = await postData(url + event.id + "/competitors", request_json)
            }

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                let event_result_response = await addEventResult(response.data.id)
                let day_result_response = await addDayResult(response.data.id, event_result_response.id)
                let match_result_response = await addMatchResult(response.data.id, day_result_response.id, response.data.handicap)
                
            }
            last_response = response
        }
        reloadEvent()
        return last_response
    }

    const addEventResult = async (event_competitor_id) => {
        let response = {data: getEventResultWithEventCompetitorId(event_competitor_id, event.event_results)}
        if(response.data === null)
        {
            let request_json = {
                event_competitor_id: event_competitor_id,
                handicap: 0,
                aggregate: 0,
                total: 0
            }
            response = await postData(url + event.id + "/eventresults", request_json)
        }
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            return response.data
        }
    }

    const addDayResult = async (event_competitor_id, event_result_id) => {
        let response = {data: getEventDayResult(event_competitor_id, event_result_id, getDayWithId(match.day_id, event.days).day_results)}
        if(response.data === null)
        {
            let request_json = {
                event_result_id: event_result_id,
                event_competitor_id: event_competitor_id,
                handicap: 0,
                aggregate: 0,
                total: 0
            }
            response = await postData(url + event.id + "/days/" + match.day_id + "/dayresults", request_json)
        }
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            return response.data
        }
    }

    const addMatchResult = async (event_competitor_id, day_result_id, handicap) => {
        let response = {data: getEventMatchResult(event_competitor_id, match.match_results)}
        if(response.data === null)
        {
            let request_json = {
                day_result_id: day_result_id,
                event_competitor_id: event_competitor_id,
                is_handicapped: event.is_handicapped,
                is_championship: event.is_championship,
                handicap: handicap * match.rounds.length / handicap_multiplier,
                aggregate: 0,
                total: 0
            }
            response = await postData(url + event.id + "/days/" + match.day_id + "/matches/" + match.id + "/matchresults", request_json)
        }
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            for(let i=0; i < match.rounds.length; i++)
            {
                addRoundResult(match.rounds[i], event_competitor_id, response.data.id)
            }
            return response.data
        }
    }

    const addRoundResult = async (round, event_competitor_id, match_result_id) => {
        let response = {data: getEventRoundResult(event_competitor_id, round.round_results)}
        if(response.data === null)
        {
            let request_json = {
                match_result_id: match_result_id,
                event_competitor_id: event_competitor_id,
                sighters: "",
                counters: "",
                sighters_converted: 0,
                aggregate: 0,
            }
            response = await postData(url + event.id + "/days/" + match.day_id + "/matches/" + match.id + "/rounds/" + round.id + "/roundresults", request_json)
        }
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            return response.data
        }
    }

    const updateCompetitor = async (formData) => {
        let response = await patchData(match_url + "/matchresults/" + formData.id + "/sethandicap?handicap=" + formData.handicap, "", {})
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvent()
            return response
        }
    }

    const deleteMatchCompetitor = async (event, id, the_event, event_competitor_id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Competitor from Match"))
        {
            let response = await deleteData(url + the_event.id + "/days/" + match.day_id + "/matches/" + match.id + "/matchresults/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                reloadEvent()
                return response
            }
        }
    }


    return(
        <>
        <OnClickButton key="add_competitors" text="Add Competitors" onClickHandler={(e) => handleClickAddCompetitor(true, "add_competitor", null)} permission_max_level={2}/>
        <BasicTable edit={true} del={true} >
            <BasicHead>
                <th>Name</th>
                <th>Discipline</th>
                <th>Grade</th>
                <th>Handicap</th>
            </BasicHead>
            <BasicBody>
                {   
                    (match && event) &&  
                    match.match_results.map((match_result) => {
                        let event_competitor = getEventCompetitor(match_result.event_competitor_id, event.competitors)
                        
                        let grade = null
                        if(event_competitor)
                        {
                            grade = getGrade(event_competitor.grade_id, event.grades)
                            return(
                                <tr key={match_result.id}>
                                    <td>{event_competitor.name}</td>
                                    <td>{event_competitor.discipline.handicap_type.name}</td>
                                    <td>{grade.name}</td>
                                    <td>{match_result.handicap}</td>
                                    <td><ALink className={"fas"} title={"Edit Hcp"} href={""} id={match_result.id} onClick={(e) => handleClickEdit(e,true, "edit_hcp", match_result.id, event.id, match.day_id, match.id, event_competitor.name, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                    <td><ALink className={"fas"} title={"Delete"} href={""} id={match_result.id} onClick={(e) => handleClick(null, null, () => deleteMatchCompetitor(e, match_result.id, event, event_competitor.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                </tr>
                            )
                        }
                            
                        return("")
                    }
                    )
                }

            </BasicBody>
        </BasicTable>
        </>
    )
}

export default EventCompetitorTab