import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import AddSeasonForm from "../../components/forms/club/AddSeasonForm"
import EditSeasonForm from "../../components/forms/club/EditSeasonForm"
import ListTableLayout from "../../components/layouts/ListTableLayout"
import BasicTable from "../../components/tables/BasicTable"
import BasicHead from "../../components/tables/BasicHead"
import BasicBody from "../../components/tables/BasicBody"
import ALink from "../../components/ALink"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { deleteData } from "../../api/deleteData"
import { useAuth } from "../../utils/hooks/useAuth"
import { formatDateTextShort } from "../../utils/formatDate"
import "./css/club-details.css"
import { useGetClubDetails } from "../../utils/hooks/useGetClubDetails"

const url = "/" + process.env.REACT_APP_CLUB + "/seasons"

function Seasons(){
    const [loading, setLoading] = useState(false)
    const {logout} = useAuth()
    const {seasons, reloadSeasons} = useGetClubDetails()

    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const addSeason = async (formData) => {
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = new Date(formData.end_date).toISOString()

        let request_json = {
            name: formData.name,
            start_date: start_date,
            end_date: end_date
        }

        let response = await postData(url, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadSeasons()
            return response
        }
        

    }

    const updateSeason = async (formData) => {
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = new Date(formData.end_date).toISOString()

        let request_json = {
            name: formData.name,
            start_date: start_date,
            end_date: end_date
        }

        let response = await patchData(url + "/", formData.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadSeasons()
            return response
        }
    }

    const deleteSeason = async (event, id) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to delete Season"))
        {
            let response = await deleteData(url + "/", id)

            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {
                reloadSeasons()
                return response
            }
        }
    }


    let modalData={
        "add": {
            title:"Add Season", 
            data: <AddSeasonForm />, 
            callback: (formData) => addSeason(formData)
        }, 
        "edit": {
            title:"Edit Season", 
            data: <EditSeasonForm />, 
            callback: (formData) => updateSeason(formData)
        },
    }

    let topButtons = [
        <OnClickButton key="add" text="+ Add Season" onClickHandler={() => handleClick(true, "add", null)} permission_max_level={2}/>
    ]

    return(
        <>
            {loading && <div>Loading...</div>}
            {seasons &&
                <ListTableLayout title="Seasons" topButtons={topButtons}>
                    <BasicTable edit={true} del={true} >
                        <BasicHead>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </BasicHead>
                        <BasicBody>
                            {
                                seasons.map((season) => {
                                    return(
                                        <tr key={season.id}>
                                            <td>{season.name}</td>
                                            <td>{formatDateTextShort(season.start_date)}</td>
                                            <td>{formatDateTextShort(season.end_date)}</td>
                                            <td><ALink className={"fas"} title={"Edit"} href={""} id={season.id} onClick={(e) => handleClickEdit(e,true, "edit", season.id, null)} permission_max_level={2}>&#xf044;</ALink></td>
                                            <td><ALink className={"fas"} title={"Delete"} href={""} id={season.id} onClick={(e) => handleClick(null, null, () => deleteSeason(e, season.id))} permission_max_level={2}>&#xf2ed;</ALink></td>
                                        </tr>
                                    )
                                })
                            }
                        </BasicBody>
                    </BasicTable>
                </ListTableLayout>
            }
        </>
    )
}

export default Seasons