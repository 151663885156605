import { useGetEvent } from '../../../utils/hooks/useGetEvent';
import BasicEditForm from '../BasicEditForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';
import FormDateInput from '../FormDateInput';
import FormSelect from '../FormSelect';
import { useGetClubDetails } from '../../../utils/hooks/useGetClubDetails';

const url = "/" + process.env.REACT_APP_CLUB + "/events/"

function EditMatchForm({match_id}) {
    const {event, getDay, getMatch} = useGetEvent()
    let match = getMatch(match_id)
    let day = getDay(match.day_id)
    return(
      <BasicEditForm url={url + event.id + "/days/" + match.day_id + "/matches"} titleFieldName={"name"} primaryKeyName={"id"}>
        <FormRow>
            <FormTextInput width="8" name="name" placeholder="Match Name" label="* Match Name" required={true} feedbackType="invalid" feedbackText="Please enter a Match Name" defaultValueField={"name"}/>  
            <FormSelect width={3} name="distance_id" label="* Distance" placeholder="Distance" required={true} feedbackType="invalid" feedbackText="Please Select Distance" defaultValueField={"distance_id"}>
                {
                    day.venue.distances && day.venue.distances.map((distance) => (
                        <option key={distance.id} value={distance.id}>{distance.name}</option>
                    ))
                }
            </FormSelect>
        </FormRow>
      </BasicEditForm>
    )
}

export default EditMatchForm