import { postData } from "../api/postData"
import { deleteData } from "../api/deleteData"
import { getAllRoundResultsInEvent } from "./eventCompetitorUtils"
import { useLoadingScreen } from "./hooks/useLoadingScreen"

export function getStatus(isOpen, upperCase = true)
{
    if(isOpen)
        return upperCase ? "OPEN" : "Open"
    return upperCase ? "CLOSED" : "Closed"
}

export function getOpenCloseButtonText(isOpen, upperCase = true)
{
    if(isOpen)
        return upperCase ? "CLOSE EVENT" : "Close Event"
    return upperCase ? "OPEN EVENT" : "Open Event"
}

export function getProcessButtonText(isProcessed, upperCase = true)
{
    if(isProcessed)
        return upperCase ? "UNPROCESS EVENT" : "Unprocess Event"
    return upperCase ? "PROCESS EVENT" : "Process Event"
}

export function boolToYesNo(bool, upperCase = true)
{
    if(bool)
        return upperCase ? "YES" : "Yes"
    return upperCase ? "NO" : "No"
}

export function getPrizeTemplateWithId(id, prizeTemplates)
{
    for(const template of prizeTemplates)
    {
        if(template.id === id)
        {
            return template
        }
    }
    return {"id": 0, "name": "None"}
}

export async function processEvent(event, logout, addTask, finishTask, updateTask)
{
    addTask("handicaps", "Updating Member's Handicaps", event.competitors.length)
    for(let i=0; i < event.competitors.length; i++)
    {
        updateTask("handicaps", i+1)
        let competitor = event.competitors[i]
        let roundResults = getAllRoundResultsInEvent(competitor.id, event)

        for(let j=0; j < roundResults.length; j++)
        {
            let roundResult = roundResults[j]
            let request_json = {
                event_id: event.id,
                round_result_id: roundResult.result.id,
                date: roundResult.date,
                aggregate_score: Math.floor(roundResult.result.aggregate),
                max_score: competitor.discipline.handicap_type.max_score,
                order: 0
            }

            let response = await postData("/" + process.env.REACT_APP_CLUB + "/members/" + competitor.discipline.state_sid + "/competitors/" + competitor.discipline.id + "/handicapscores", request_json)
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {

            }
        }
        
    }
    finishTask("handicaps")
}

export async function unprocessEvent(event, logout, addTask, finishTask, updateTask)
{
    addTask("handicaps", "Updating Member's Handicaps", event.competitors.length)
    for(let i=0; i < event.competitors.length; i++)
    {
        updateTask("handicaps", i+1)
        let competitor = event.competitors[i]
        let roundResults = getAllRoundResultsInEvent(competitor.id, event)

        for(let j=0; j < roundResults.length; j++)
        {
            let roundResult = roundResults[j]

            let response = await deleteData("/" + process.env.REACT_APP_CLUB + "/members/" + competitor.discipline.state_sid + "/competitors/" + competitor.discipline.id + "/handicapscoresbyresultid/", roundResult.result.id)
            if(response.error && response.error.response.status === 401)
            {
                logout()
            }
            else
            {

            }
        }
    }
    finishTask("handicaps")
}