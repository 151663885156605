import { postData } from "../../api/postData"
import { patchData } from "../../api/patchData"
import { deleteData } from "../../api/deleteData"
import OnClickButton from "../../components/OnClickButton"
import Table from "../../components/Table"
import ListTableLayout from "../../components/ListTableLayout"
import AddMemberForm from "../../components/forms/members/AddMemberForm"
import EditMemberForm from "../../components/forms/members/EditMemberForm"
import { useState, useEffect } from "react"
import { useAuth } from "../../utils/hooks/useAuth"
import { useGetData } from "../../api/hooks/useGetData"
import { getBodyData } from "../../utils/getBodyData"
import { useModal } from "../../utils/hooks/useModal"
import ChangeMemberPasswordForm from "../../components/forms/members/ChangeMemberPasswordForm"
import { useGetMembers } from "../../utils/hooks/useGetMembers"
import { useGetGlobal } from "../../utils/hooks/useGetGlobal"

const url = "/" + process.env.REACT_APP_CLUB + "/members/"
const user_url = "/users/"

const router_url = "/home/members/"

function MemberDetails()
{
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)

    const {modalOpen} = useModal()
    const {user, logout} = useAuth()

    const {members, reloadMembers} = useGetMembers()
    const {userRoles, reloadUserRoles} = useGetGlobal()

    const headData = ["State ID", "Name", "Email", "Role"]
    const fields = ["state_sid", "user.full_name", "user.email", "user.role.display_name"]
    const urlFields = [true, true, false, false]
    const idField = "state_sid"

    let bodyData = getBodyData(members, fields, idField, urlFields)

    function getRole(user_role)
    {
        let ret_role = {name: "", display_name: "", level: 3}
        userRoles.forEach(role => {
            if(role.name === user_role)
            {
                ret_role = role
            }
        });
        return ret_role
    }

    const addMember = async (formData) => 
    {
        let user_request_json = 
        {
            username: formData.username, 
            email: formData.email, 
            full_name: formData.first_name + " " + formData.last_name, 
            user_role: formData.user_role, 
            disabled: false, 
            password: formData.password, 
            password_again: formData.password2
        }
        let member_request_json = 
        {
            username: formData.username, 
            state_sid: formData.username,
            nraa_sid: formData.nraa_sid,
            address1: formData.address,
            address2: formData.address2,
            suburb: formData.suburb,
            state: formData.state,
            postcode: formData.postcode,
            phone: formData.phone,
            active: true,
            private: false,
        }

        let user_response = await postData(user_url, JSON.stringify(user_request_json))
        let member_response = await postData(url, JSON.stringify(member_request_json))
        if((user_response.error && member_response.error) && (user_response.error.response.status === 401 || member_response.error.response.status === 401))
        {
            logout()
        }

        member_request_json.user = {username: user_request_json.username, email: user_request_json.email, full_name: user_request_json.full_name, user_role: user_request_json.user_role, disabled: user_request_json.disabled}
        member_request_json.competitors = []
        
        member_request_json.user.role = getRole(user_request_json.user_role)

        //setData([member_request_json, ...data])
        reloadMembers()

        return member_request_json
    }

    const updateMember = async (formData) => 
    {
        let user_request_json = 
        {
            username: formData.username, 
            email: formData.email, 
            full_name: formData.full_name, 
            user_role: formData.user_role, 
            disabled: false,
        }
        let member_request_json = 
        {
            username: formData.username, 
            state_sid: formData.username,
            nraa_sid: formData.nraa_sid,
            address1: formData.address,
            address2: formData.address2,
            suburb: formData.suburb,
            state: formData.state,
            postcode: formData.postcode,
            phone: formData.phone,
            active: true,
            private: false,
        }

        let user_response = null
        let member_response = null

        if(user.username == formData.username)
        {
            user_response = await patchData(user_url, "me", JSON.stringify(user_request_json))
            member_response = await patchData(url, "me", JSON.stringify(member_request_json))
            if((user_response.error && member_response.error) && (user_response.error.response.status === 401 || member_response.error.response.status === 401))
            {
                logout()
            }
        }
        else
        {
            user_response = await patchData(user_url, formData.username, JSON.stringify(user_request_json))
            member_response = await patchData(url, formData.username, JSON.stringify(member_request_json))
            if((user_response.error && member_response.error) && (user_response.error.response.status === 401 || member_response.error.response.status === 401))
            {
                logout()
            }
        }

        if(member_response.error == null)
        {
            let member_data = null
            for(let i=0; i < members.length; i++)
            {
                if(members[i].username == formData.username)
                {
                    member_data = members[i]
                }
            }

            if(member_data)
            {
                member_request_json.state_sid = user_request_json.username
                member_request_json.user = {username: user_request_json.username, email: user_request_json.email, full_name: user_request_json.full_name, user_role: user_request_json.user_role, disabled: user_request_json.disabled}
                member_request_json.competitors = member_data.competitors
                member_request_json.user.role = member_data.user.role

                if(member_data.username == member_request_json.username)
                {
                    if(member_request_json.user.user_role != member_data.user.user_role)
                    {
                        reloadMembers()
                    }
                    
                    const retData = members.map((data_item) => {
                        if(data_item.username == member_request_json.username)
                        {
                            return member_request_json
                        }
                        else return data_item
                    })
                    reloadMembers()  
                }
            }
        }
        return member_request_json
    }

    const deleteMember = async (event, username) => {
        event.preventDefault()
        if(window.confirm("Are you sure you want to Delete Member?"))
        {
            let member_response = await deleteData(url, username)
            let user_response = await deleteData(user_url, username)

            if((user_response.error && member_response.error) && (user_response.error.response.status === 401 || member_response.error.response.status === 401))
            {
                logout()
            }
            else
            {
                //setData(data.filter(element => element.username !== username))
                reloadMembers()
                return member_response
            }
        }
    }
    
    let modalData = {
        "add": {
            title:"Add New Member", 
            data: <AddMemberForm />, 
            callback: (formData) => addMember(formData)
        }, 
        "edit": {
            title: "Edit Member", 
            data: <EditMemberForm />, 
            callback: (formData) => updateMember(formData)
        },
        "change_password": {
            title: "Change Password", 
            data: <ChangeMemberPasswordForm />, 
            callback: (formData) => changePassword(formData)
        }
    }
    

    const editMember = (e, id) => {
        e.preventDefault();
        modalOpen(modalData["edit"], id)
    }


    const handleChangePassword = (event, id) => {
        event.preventDefault()
        modalOpen(modalData["change_password"], id)
    }

    const changePassword = async (formData) => {
        let request_json = {
            password: formData.password,
            password_again: formData.password2
        }

        let request_url = user_url + formData.id + "/changepassword" 
        if(formData.current_password)
        {
            request_url = user_url + "me/changepassword" 
            request_json.current_password = formData.current_password
        }

        let response = await patchData(request_url, "", JSON.stringify(request_json))
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            return response
        }
    }

    return (
        <>
        {loading && <div>Loading...</div>}
        {bodyData && <div><h3>Member Details</h3><ListTableLayout 
            buttons={[
                {key: "add_button", text: "+ Add New Member", modal: true, modalDataKey: "add", permission_max_level: 2},
            ]}
            table={
                <Table customClass={"sm-table member-details"} theadData={headData} tbodyData={bodyData} url={router_url} edit={true} editHandler={editMember} del={(user.role.level <= 2)} deleteHandler={deleteMember} extraOptions={[{"title": "Change Password", "name": <>&#xf2c2;</>, "className": "fas", "link": true, "link_handler": handleChangePassword, "permission_max_level": 2, "permission_id_override": user.username}]}/>
            }
            modalData={modalData}
                />
        </div>}
        </>
    )
    
}

export default MemberDetails