import { getEventCompetitor } from "../../../utils/eventCompetitorUtils"
import { useContext } from "react"
import { Fragment } from "react"
import { getAllRoundResults, getCounterShotArrayFromResult } from "../../../utils/eventCompetitorUtils"
import ShotCell from "../../ShotCell"
import ScoreEntry from "../../ScoreEntry"
import { useGetEvent } from "../../../utils/hooks/useGetEvent"

import { useGetData } from "../../../api/hooks/useGetData"
import { useState } from "react"


function EventScoresTab({match, children})
{
    const {event} = useGetEvent()

    const url = "/" + process.env.REACT_APP_CLUB + "/events/" + event.id + "/days/" + match.day_id + "/matches/" + match.id

    if(match)
    {
        return(
            match.match_results.map((match_result) => {
                let event_competitor = getEventCompetitor(match_result.event_competitor_id, event.competitors)
                let round_results = getAllRoundResults(event_competitor.id, match.rounds)
                return(
                    <ScoreEntry key={match_result.id} event_competitor={event_competitor} match_result={match_result} round_results={round_results} match={match}/>
                )
            })        
        )
    }
    return ("")
}

export default EventScoresTab