import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";
import { useAuth } from "./useAuth";

const MemberContext = createContext()

export const MemberProvider = ({ username, children }) => 
{
    const {user} = useAuth()
    const [memberRefresh, setMemberRefresh] = useState(0)
    const [member, setMember] = useState(null)
    //const [competitor, setCompetitor] = useState(null)

    let url = "/" + process.env.REACT_APP_CLUB + "/members/" + username
    if(username === user.username && user.user_role === "superadmin")
    {
        url = "/na/members/" + username
    }

    const getMemberData = useMemo(() => async () => {
        if(username)
            setMember((await doGet(url)).data)
    }, [memberRefresh, username])


    useEffect(() => {
            getMemberData()
        },[getMemberData]
    );


    function reloadMember()
    {
        setMemberRefresh(memberRefresh + 1)
    }


    function getCompetitor(competitor_id)
    {
        if(member && member.competitors)
        {
            for(let i=0; i < (member.competitors).length; i++)
            {
                let competitor = member.competitors[i]
                if(competitor.id === parseInt(competitor_id))
                {
                    return competitor
                }
            }
        }
        return null
    }

    return <MemberContext.Provider value={{member, reloadMember, getCompetitor}}>{children}</MemberContext.Provider>;
}

export const useGetMember = () => 
{
    return useContext(MemberContext);
};