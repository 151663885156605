import { useState } from "react"
import { useGetData } from "../../api/hooks/useGetData"
import { useModal } from "../../utils/hooks/useModal"
import OnClickButton from "../../components/OnClickButton"
import EditEventForm from "../../components/forms/events/EditEventForm"
import AddDayForm from "../../components/forms/events/AddDayForm"
import { doGet } from "../../api/doGet"
import { patchData } from "../../api/patchData"
import { postData } from "../../api/postData"
import { useAuth } from "../../utils/hooks/useAuth"
import "./css/events.css"
import { getStatus, getOpenCloseButtonText, boolToYesNo, getPrizeTemplateWithId, processEvent, unprocessEvent, getProcessButtonText} from "../../utils/eventUtils"
import { useParams } from "react-router-dom"
import EventLayout from "../../components/layouts/EventLayout"
import { useGetClubDetails } from "../../utils/hooks/useGetClubDetails"
import { useGetEvents } from "../../utils/hooks/useGetEvents"
import { useGetGlobal } from "../../utils/hooks/useGetGlobal"
import { useGetEvent } from "../../utils/hooks/useGetEvent"
import { getAllRoundResultsInEvent } from "../../utils/eventCompetitorUtils"
import { useLoadingScreen } from "../../utils/hooks/useLoadingScreen"

const url = "/" + process.env.REACT_APP_CLUB + "/events"

function EventDetail() {
    let params = useParams()

    const [loading, setLoading] = useState(false)
    const {logout} = useAuth()


    const {currentModalData, showModal, modalOpen, modalClose, modalSave, modalError} = useModal()
    const {venues, seasons, grade_templates} = useGetClubDetails()
    const {off_rifle_templates, handicap_templates} = useGetEvents()
    const {classes} = useGetGlobal()
    const {event, reloadEvent} = useGetEvent()
    const {events, reloadEvents} = useGetEvents()
    const {addTask, finishTask, updateTask} = useLoadingScreen()

    const handleClick = (modal, modalDataKey, clickHandler) => {
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey])

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickEdit = (event, modal, modalDataKey, id, clickHandler) => {
        event.preventDefault()
        if(modal && modalDataKey)
        {
            modalOpen(modalData[modalDataKey], id)

        }
        else if(clickHandler)
        {
            clickHandler()
        }
    }

    const handleClickOpenClose = async (event, id, currentState) => {
        event.preventDefault()
        let new_state = ""
        if(currentState === true)
        {
            new_state = "close"
        }
        else
        {
            new_state = "open"
        }

        let response = await patchData(url + "/" + id + "/" + new_state, "")
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvent()
            return response
        }
    }

    const handleClickProcess = async (e, id, currentState) => {
        e.preventDefault()
        let new_state = ""
        if(currentState === true)
        {
            new_state = "unprocess"
        }
        else
        {
            new_state = "process"
        }

        let response = await patchData(url + "/" + id + "/" + new_state, "")
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            if(new_state === "process")
            {
                addTask("process", "Processing Event")
                await processEvent(event, logout, addTask, finishTask, updateTask)
            }
            else
            {
                addTask("process", "Un-processing Event")
                await unprocessEvent(event, logout, addTask, finishTask, updateTask)
            }
            reloadEvent()
            reloadEvents()
            finishTask("process")
            return response
        }
        

    }

    const updateEvent = async (formData) => {
        let start_date = new Date(formData.start_date).toISOString()
        let end_date = null
        let is_multi_day = false
        let is_multi_match = false
        let is_championship = false
        let is_handicapped = false
        let grade_ids = (formData.selected_grades).split(",")

        let grades_array = []
        for(let i=0; i < grade_ids.length; i++)
        {
            let grade_id = grade_ids[i]
            for(let j=0; j < grade_templates.length; j++)
            {
                if(grade_templates[j].id == grade_id)
                {
                    grades_array.push(grade_templates[j])
                } 
            }
        }


        if(formData.end_date)
            end_date = new Date(formData.end_date).toISOString()
        else
            end_date = new Date(formData.start_date).toISOString()

        if(formData.is_multi_day)
            is_multi_day = true

        if(formData.is_multi_match)
            is_multi_match = true

        if(formData.is_championship)
            is_championship = true

        if(formData.is_handicapped)
            is_handicapped = true


        let request_json = {
            name: formData.name,
            is_multi_day: is_multi_day,
            is_multi_match: is_multi_match,
            start_date: start_date,
            end_date: end_date,
            grades: JSON.stringify(grades_array),
            is_championship: is_championship,
            is_handicapped: is_handicapped,
            or_prize_template_id: parseInt(formData.or_prize_template_id),
            h_prize_template_id: parseInt(formData.h_prize_template_id),
        }


        let response = await patchData(url + "/", formData.id, request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvent()
            return response
        }
    }

    const addDay = async (formData) => {
        let date = new Date(formData.date).toISOString()

        let request_json = {
            name: formData.name,
            venue_id: parseInt(formData.venue_id),
            date: date,
            or_prize_template_id: event.or_prize_template_id,
            h_prize_template_id: event.h_prize_template_id,
        }

        let response = await postData(url + "/" + event.id + "/days", request_json)
        if(response.error && response.error.response.status === 401)
        {
            logout()
        }
        else
        {
            reloadEvent()
            return response
        }
    }

    let modalData={
        "edit": {
            title:"Edit Event", 
            data: <EditEventForm off_rifle_prize_templates={off_rifle_templates} handicap_prize_templates={handicap_templates} />, 
            callback: (formData) => updateEvent(formData)
        },
        "add_day": {
            title:"Add Day", 
            data: <AddDayForm />, 
            callback: (formData) => addDay(formData)
        },
    }

    let topButtons = []
    if(event)
    {
        topButtons = [
            <OnClickButton key="process" text={getProcessButtonText(event.processed, false)} onClickHandler={(e) => handleClickProcess(e, event.id, event.processed)} permission_max_level={2} disabled={event.open}/>,
            <OnClickButton key="open_close" text={getOpenCloseButtonText(event.open, false)} onClickHandler={(e) => handleClickOpenClose(e, event.id, event.open)} permission_max_level={2} disabled={event.processed}/>,
            <OnClickButton key="edit" text="Edit Event" onClickHandler={(e) => handleClickEdit(e,true, "edit", event.id, null)} permission_max_level={2}/>,
            <OnClickButton key="add_day" text="Add Day" onClickHandler={(e) => handleClick(true, "add_day", null)} permission_max_level={2}/>,
        ]
    }


    // let middleButtons = []
    // if(event)
    // {
    //     middleButtons = [
    //         <OnClickButton key="add_competitors" text="Add Competitors" onClickHandler={(e) => handleClickEdit(e,true, "edit", event.id, null)} permission_max_level={2}/>,
    //         <OnClickButton key="add_day" text={getOpenCloseButtonText(event.open, false)} onClickHandler={(e) => handleClickOpenClose(e, event.id, event.open)} permission_max_level={2}/>
    //     ]
    // }

    return(
        <>
            {loading && <div>Loading...</div>}
            {event &&
                <>
                <EventLayout topButtons={topButtons} />
                </>
            }
        </>
    )
}

export default EventDetail