import {LogoutButton} from "../components/LoginButtons";
import { useNavigate, Link, Outlet, NavLink} from "react-router-dom";
import { api_get } from "../api/api";

import { useState, useEffect, useMemo} from "react";
import { useAuth } from "../utils/hooks/useAuth";

import ALink from "../components/ALink";
import getme from "../api/getme";
import packageData from "../../package.json"

import { useGetData } from "../api/hooks/useGetData";

function Home(){
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useState({})

    const [version, setVersion] = useState({version: "0.0.0"})
    useGetData("/db/version", null, setVersion, false, null)

    const {user, logout} = useAuth()
    const value = useMemo(
        () => {
            if(user)
            {
                getme().then(
                    (response) => {
                        if(response)
                        {
                            navigate(window.location.pathname)
                        }
                        else
                        {
                            logout()
                        }
                    }
                )
            }
            },
        []
      );

    async function handleLogoutClick(e)
    {
        try
        {
            await api_get("/users/logout/");
            logout()
        }
        catch(error)
        {
            console.log(error);
        }
    }

    return (
        <>
        <nav className="nav-header">
            <div className="left-header">
                <NavLink to="/home" end>Admin Home</NavLink>
                <NavLink to="/home/members">Members</NavLink>
                <NavLink to="/home/clubdetails">Club Details</NavLink>
                <NavLink to="/home/events">Events</NavLink>
                <NavLink to="/home/trophies">Trophies</NavLink>
            </div>
            <div className="right-header">
                <span>
                    Logged in as <ALink href={"/home/members/" + user.username}>{user.full_name}</ALink>
                </span>
                <LogoutButton onLogoutClick={handleLogoutClick}/>
            </div>
        </nav>
        
        <Outlet />

        <footer className="footer">
            <div className="footer-copyright">Shooter Manager (Maitland Rifle Club) v{packageData.version} &copy; 2024</div>
            <div className="footer-api-version">API Version:  
                {version && " " + version.version}
            </div>
        </footer>
        </>
    );
}

export default Home