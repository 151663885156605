import { useGetEvent } from '../../../utils/hooks/useGetEvent';
import BasicEditForm from '../BasicEditForm';
import FormRow from '../FormRow';
import FormTextInput from '../FormTextInput';

const url = "/" + process.env.REACT_APP_CLUB + "/events/"

function EditRoundForm({match_id}) {
    const {event, getDay, getMatch} = useGetEvent()
    let match = getMatch(match_id)
    return(
      <BasicEditForm url={url + event.id + "/days/" + match.day_id + "/matches/" + match_id + "/rounds"} titleFieldName={"round_num"} primaryKeyName={"id"}>
        <FormRow>
            <FormTextInput width="4" name="round_num" placeholder="Round Number" label="* Round Number (e.g. 1)" required={true} feedbackType="invalid" feedbackText="Please enter a Round Number" defaultValueField={"round_num"}/>  
            <FormTextInput width="4" name="counters" placeholder="Number of Counters" label="* Number of Counters" required={true} feedbackType="invalid" feedbackText="Please enter the number of counting shots" defaultValueField={"counters"}/> 
            <FormTextInput width="4" name="sighters" placeholder="Number of Sighters" label="* Number of Sighters" required={true} feedbackType="invalid" feedbackText="Please enter the number of sighters" defaultValueField={"sighters"}/> 
        </FormRow>
      </BasicEditForm>
    )
}

export default EditRoundForm