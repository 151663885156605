import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { doGet } from "../../api/doGet";
import { useAuth } from "./useAuth";

const HandicapScoresContext = createContext()

export const HandicapScoresProvider = ({ username, competitor_id, children }) => 
{
    const {user} = useAuth()
    const [handicapScoresRefresh, setHandicapScoresRefresh] = useState(0)
    const [handicapScores, setHandicapScores] = useState(null)
    //const [competitor, setCompetitor] = useState(null)

    let url = "/" + process.env.REACT_APP_CLUB + "/members/" + username + "/competitors/" + competitor_id + "/handicapscores"

    const getHandicapScoreData = useMemo(() => async () => {
        if(username && competitor_id)
            setHandicapScores((await doGet(url)).data)
    }, [handicapScoresRefresh, username, competitor_id])


    useEffect(() => {
            getHandicapScoreData()
        },[getHandicapScoreData]
    );


    function reloadHandicapScores()
    {
        setHandicapScoresRefresh(handicapScoresRefresh + 1)
    }


    return <HandicapScoresContext.Provider value={{handicapScores, reloadHandicapScores}}>{children}</HandicapScoresContext.Provider>;
}

export const useGetHandicapScores = () => 
{
    return useContext(HandicapScoresContext);
};