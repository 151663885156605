import { useAuth } from "../utils/hooks/useAuth"

import { useLoadingScreen } from "../utils/hooks/useLoadingScreen"

function HomeDetail()
{
    const {user} = useAuth()


    return(
        <div className="content">
            {user.role.level < 3 ?
                <>
                <div className="DontPrint">               
                <h3>Welcome {user.full_name}</h3>
                <p>
                    As an Administrator you have access to special functions that other members do not. In the current version only the "Members" tab at the top of the page works.
                </p>
                </div>
                <div className="PrintThis">
                <h4>Quick Start Guide</h4>
                <ol>
                    <li>Click <a href="javascript:window.print()">Here</a> to Print a copy of this guide.</li>
                    <li>Click on the "Members" tab to enter the Members Area. The Members Area has 3 pages, Member Details, Handicap Disciplines and Member Handicaps</li>
                </ol>
                <h5>Member Details</h5>
                <ol>
                    <li>The Member Details page will show a list of members. From here you will be able to add a new member, edit an existing member, change a member's password and delete a member.</li>
                    <li>To add a new member click "+ Add New Member" and fill in the form. Fields marked with a * are mandatory. Click "Save changes" when finished.</li>
                    <li>Click on a member's name or state ID to bring up their profile page. On this page you can edit their information and add the disciplines that they shoot.</li>
                    <li>To add a discipline click the "Add Discipline" button. Fill out the form and click "Save changes". (If the member does not have a target id assigned yet just use 0. It can be changed later.)</li>
                </ol>
                <h5>Handicap Disciplines</h5>
                <ol>
                    <li>Click on the "Handicap Disciplines" tab to bring up a list of current Disciplines being used.</li>
                    <li>You can view, add, edit and delete disciplines from this page.</li>
                    <li>This has all been set up for you in advance so you are advised not to touch anything here for the time being.</li>
                </ol>
                <h5>Member Handicaps</h5>
                <ol>
                    <li>Click on the "Member Handicaps" tab to bring up a list of current handicaps</li>
                    <li>At the top there are currently buttons for "Add Member Scores" and "Print"</li>
                    <li>To add scores click "Add Member Scores"</li>
                    <li>Enter the date of the shoot and click "Add Member" to add your first member</li>
                    <li>Select the member's name from the first drop down menu and the discipline they shot from the second drop down.</li>
                    <li>Enter the round scores with the V's/X's removed and separate them with a comma. For example a 46.5 and a 48.7 would be entered as 46,48</li>
                    <li>Click "Add Member" to add another member and repeat steps 5-7 until all scores have been entered.</li>
                    <li>Once all members have been added you can click "Save changes" to submit all the scores.</li>
                </ol>
                <h5>Member Handicap Summary & Score History</h5>
                <ol>
                    <li>From the "Member Handicaps" page you can click a member's discipline to show the handicap summary and score history page.</li>
                    <li>At the top of the page is two options. "Add Score" and "Recalculate Handicap"</li>
                    <li>The page has 2 sections.
                        <ul>
                            <li>
                                Handicap Summary, which shows the member's name, discipline and their handicap (in exact and rounded form).
                            </li>
                            <li>
                                Score History, which shows a list of past scores used for handicapping.
                            </li>
                        </ul>
                    </li>
                    <li>The "Add Score" button allows you to add a score. Scores should be entered in the exact same way as mentioned above.</li>
                    <li>The "Recalculate Handicap" button will recalculate the member's handicap. Handicaps should recalculate automatically as scores are added and deleted, however this button can be used as a last resort.</li>
                </ol>
                <p>Hopefully this guide was helpful. Please contact Tim (<a href="mailto:tim@tjdsoftware.com.au">tim@tjdsoftware.com.au</a>) if further help is needed.</p>
                </div>
                </>
            :
                "Welcome Member. I'll put some more useful information here soon. Feel free to click around to see what you can do."
            }
        </div>
    )
}

export default HomeDetail